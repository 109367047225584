import { StoryboardType } from '../../../types';

export const storyboardSubclinicalBleedAnimation = (
  maxRotation: number,
  quality: string | null,
  cmsData: any
) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  for (let i = 0; i < maxRotation; i++) {
    const frameNumber =
      i < 10 ? `000${i}` : i < 100 ? `00${i}` : i < 1000 ? `0${i}` : `${i}`;

    const months = i < 12;
    storyboardArr.push({
      _id: i,
      img: `${appAssets}/subclinicalBleed/${
        quality ? quality : ''
      }KneeBleed_SubClinical_${frameNumber}_Ultra.jpg`,
      timePeriod: {
        title: months
          ? cmsData.otherItems.timescale[3]
          : cmsData.otherItems.timescale[4],
        step: months ? 1 : 5,
        divisible: months ? 2 : 10,
      },
      subtitle: 'SubclinicalBleedSubtitles',
      video: `${appAssets}/kneeBleedSubclinical.mp4`,
      allowControls: true,
    });
  }
  return storyboardArr;
};
