import React from 'react';
import { themeColor } from '../cmsConfig';
export function Tab(props: {
  onClickEvent: () => void;
  title: string;
  selected: boolean;
}) {
  const { onClickEvent, title, selected } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 1,
        cursor: 'pointer',
        minWidth: 100,
        padding: 5,
        border: 'none',
        borderBottom: 'none',
        background: selected ? themeColor : 'rgba(0,0,0,0.05)',
      }}
      onClick={() => onClickEvent()}
    >
      <button
        style={{
          border: 'none',
          background: 'none',
          cursor: 'pointer',
          textTransform: 'uppercase',
        }}
      >
        {title}
      </button>
    </div>
  );
}
