import React, { useEffect } from 'react';
import { themeColors, H2Splash } from '../styles';
import FadeLoader from 'react-spinners/FadeLoader';
import branding from '../assets/sobi_white.svg';

export const LoaderSobi = (props: { visible: boolean }) => {
  const { visible } = props;
  const [opacity, setOpacity] = React.useState(0);

  useEffect(() => {
    if (visible && opacity < 1) {
      setOpacity(1);
    } else if (!visible && opacity > 0) {
      setOpacity(0);
    }
  }, [visible, opacity]);

  return (
    <div
      style={{
        transition: 'all 1s',
        position: 'fixed',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: opacity,
        zIndex: visible ? 1001 : -1,
        width: '100vw',
        height: '100vh',
        background: themeColors.gradient.primary,
      }}
    >
      <H2Splash>
        <img src={branding} style={{ width: 100 }} alt="branding" />
      </H2Splash>
      <div
        style={{
          position: 'relative',
          marginLeft: -84,
          marginTop: -90,
        }}
      >
        <FadeLoader
          color={themeColors.white.primary}
          height={30}
          width={30}
          radius={20}
          margin={70}
          speedMultiplier={0.8}
        />
      </div>
    </div>
  );
};
