import React from 'react';
import { PageType } from '../types';
import { Fade } from '.';
import { ContentContext } from '../context';
import { ThemeContext } from '../context';
import { MenuButtonStyle, ChildMenuButtonStyle } from './styles';
import parse from 'html-react-parser';
import { menuJsonToArray } from '../utils/menuJsonToArray';

export function MenuMobile(props: {
  menuItems: any[];
  page: PageType;
  setMenuPage: any;
  setDeviceType: (event: string) => void;
  shouldRender: boolean;
}) {
  //PROPS
  const { page, setMenuPage, menuItems, shouldRender } = props;

  //STATES
  const [isOpen, setIsOpen] = React.useState(-1);

  //CONSTS
  const parentArr = menuJsonToArray(menuItems).filter((item) => !item.parent);

  //FUNCTIONS
  if (!shouldRender) {
    return null;
  }

  return (
    <Fade
      visible={shouldRender}
      zIndex={50}
      style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
    >
      <ThemeContext.Consumer>
        {({ theme }) => (
          <div
            style={{
              position: 'fixed',
              top: 0,
              zIndex: 100,
              display: 'flex',
              width: '100%',
              background: theme.grey.dark,
              alignItems: 'flex-start',
              justifyContent: 'center',
              maxHeight: 36,
              overflow: 'display',
            }}
          >
            {[...parentArr].map((item: any, index: number) => {
              const childIsActive = menuJsonToArray(menuItems).filter(
                (subItem) =>
                  page.page === subItem.id && subItem.parent === item.id
              );

              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    position: 'relative',
                  }}
                  key={`menu-itemw-${index}`}
                >
                  <ContentContext.Consumer>
                    {({ cmsData, editButton }) => editButton(item.file, 'menu')}
                  </ContentContext.Consumer>
                  <MenuButtonStyle
                    key={`menu-item-${index}`}
                    theme={theme}
                    isActive={page.page === item.id || childIsActive.length > 0}
                    onClick={() => {
                      setMenuPage({
                        page: item.id,
                        visible: true,
                        transitionFrame: -1,
                        themeNumber: item.themeNumber,
                      });
                    }}
                    onMouseEnter={() => setIsOpen(item.id)}
                    onMouseLeave={() => setIsOpen(-1)}
                  >
                    {parse(item.label)}
                  </MenuButtonStyle>
                  <ContentContext.Consumer>
                    {({ cmsData, editButton, cmsIsEnabled }) => (
                      <>
                        {menuJsonToArray(menuItems)
                          .filter(
                            (subItem) =>
                              subItem.parent &&
                              subItem.parent === item.id &&
                              subItem.visible !== false
                          )
                          .map((subItem: any, subIndex: number) => {
                            const isOpenSub =
                              isOpen === item.id || cmsIsEnabled;

                            return (
                              <div
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  position: 'relative',
                                }}
                                key={`subMenu-${index}-${subIndex}`}
                              >
                                {editButton(subItem.file, 'menu')}

                                <ChildMenuButtonStyle
                                  isActive={page.page === subItem.id}
                                  isOpen={isOpenSub}
                                  theme={theme}
                                  key={`menu-item-${index}-${subItem.id}`}
                                  onClick={() => {
                                    setMenuPage({
                                      page: subItem.id,
                                      visible: true,
                                      transitionFrame: -1,
                                      themeNumber: item.themeNumber,
                                    });
                                  }}
                                  onMouseEnter={() => {
                                    setIsOpen(item.id);
                                  }}
                                  onMouseLeave={() => {
                                    setIsOpen(-1);
                                  }}
                                >
                                  {subItem.label}
                                </ChildMenuButtonStyle>
                              </div>
                            );
                          })}
                      </>
                    )}
                  </ContentContext.Consumer>
                </div>
              );
            })}
          </div>
        )}
      </ThemeContext.Consumer>
    </Fade>
  );
}
