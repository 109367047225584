import React from 'react';
import parse from 'html-react-parser';
import { ContentPanel } from './styles';
import { ContentContext, ThemeContext } from '../../../../context';

export function Subtitle(props: {
  fileName: string;
  isMobile?: boolean;
  borders?: boolean;
  fadeOut?: boolean;
}) {
  const { isMobile, fileName, fadeOut } = props;
  return (
    <>
      <ThemeContext.Consumer>
        {({ theme }) => (
          <ContentContext.Consumer>
            {({ cmsData, editButton }) => {
              return (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '5vw',
                    boxSizing: 'border-box',
                    overflow: 'visible',
                    zIndex: isMobile ? 10 : 2,
                    alignSelf: 'center',
                    margin: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    transition: 'opacity 0.5s ease-in-out',
                    opacity: fadeOut ? 0.2 : 1,
                  }}
                >
                  <div
                    style={{
                      maxWidth: isMobile ? '80%' : 500,
                      position: 'relative',
                    }}
                  >
                    {cmsData.subtitles[fileName].label !== '' && (
                      <h2
                        style={{
                          fontSize: '3rem',
                          padding: 10,
                          margin: 0,
                          color: theme.brand.primary,
                          lineHeight: 1,
                          letterSpacing: 0,
                          fontFamily: 'proxima-nova, sans-serif',
                          fontWeight: 900,
                        }}
                      >
                        {parse(cmsData.subtitles[fileName].label)}
                      </h2>
                    )}
                    {editButton(fileName, 'subtitles')}
                    <ContentPanel theme={theme}>
                      {parse(
                        cmsData.subtitles[fileName].content[0].content[0]
                          .html_content
                      )}
                    </ContentPanel>
                  </div>
                </div>
              );
            }}
          </ContentContext.Consumer>
        )}
      </ThemeContext.Consumer>
    </>
  );
}
