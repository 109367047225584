import { fileDirectory } from '../cmsConfig';
import { getDataNoServer } from './getDataNoServer';
import { checkServer } from './serverStatus';

export const getData = async (
  hotspotFile: any,
  page: any,
  setContent: any,
  language: any,
  type: any,
  sourceData: any
) => {
  const status = await checkServer(fileDirectory);
  if (status.status !== 200) {
    getDataNoServer(hotspotFile, page, setContent, type, sourceData);
    return;
  }

  fetch(`${fileDirectory}/api/${hotspotFile}/${page}/${language}/${type}`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  })
    .then(async (response) => {
      const content = await response.json();
      const contentParsed = content.message;
      setContent({
        content: contentParsed.content,
        textContent: contentParsed.textContent,
        pages: contentParsed.pages,
        type: contentParsed.type,
        label: contentParsed.label,
        media: contentParsed.media,
        hoverLabel: contentParsed.hoverLabel,
      });
    })
    .catch((err) => {
      if (err) {
        let payload = {
          file: hotspotFile,
          content: {},
        };

        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        };
        fetch(`${fileDirectory}/api`, requestOptions).then((response) =>
          response.json()
        );
      }
    });
};
