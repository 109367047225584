import React from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { HotspotContext } from '../context';

export function editButton(file: string, type: string, content: any) {
  return (
    <HotspotContext.Consumer>
      {({ selectedHotspot, setSelectedHotspot }) => (
        <EditIcon
          onClick={() => {
            setSelectedHotspot({
              file: file,
              page: 0,
              type: type,
              index: 0,
            });
          }}
          sx={{
            height: 14,
            width: 14,
            color: 'black',
            position: 'absolute',
            right: 0,
            top: 0,
            zIndex: 10,
            borderRadius: '50%',
            background: '#fff',
            padding: '3px',
            cursor: 'pointer',
            opacity: 0.8,
            pointerEvents: 'all',
          }}
        />
      )}
    </HotspotContext.Consumer>
  );
}
