import React, { useRef, useEffect } from 'react';
import {
  mouseDownEv,
  touchStartEv,
  mouseUpEv,
  touchEndEv,
  //wheelEv,
  mouseOutEv,
  mouseMoveEv,
  // touchMoveEv,
  moveToTarget,
} from '../../utils/mouseEvents';
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react';
import { throttle } from '../../utils/throttle';

const useGesture = createUseGesture([dragAction, pinchAction]);

export function ControlInterface(props: {
  setLevel: any;
  rotation: number;
  setRotation: any;
  rotationTarget: number;
  setRotationTarget: any;
  level: number;
  maxRotation: number;
  setZoom?: any;
}) {
  const {
    setLevel,
    rotation,
    setRotation,
    rotationTarget,
    maxRotation,
    setZoom,
  } = props;

  const mouseDown = useRef(false);
  const isMoving = useRef(false);
  const mouseStart = useRef({ x: 0, y: 0 });
  const controlRef = useRef(null);
  const timeout = useRef<any>(undefined);

  useEffect(() => {
    const handler = (e: Event) => e.preventDefault();
    document.addEventListener('gesturestart', handler);
    document.addEventListener('gesturechange', handler);
    document.addEventListener('gestureend', handler);
    return () => {
      document.removeEventListener('gesturestart', handler);
      document.removeEventListener('gesturechange', handler);
      document.removeEventListener('gestureend', handler);
    };
  }, []);

  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
        if (pinching) return cancel();
      },

      onPinch: ({
        origin: [ox, oy],
        first,
        movement: [ms],
        offset: [s, a],
        memo,
      }) => {
        if (ms > 1) {
          setZoom && setZoom(1);
        } else {
          setZoom && setZoom(0);
        }
      },
    },
    {
      target: controlRef,
      pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
    }
  );

  useEffect(() => {
    if (isMoving.current === true) {
      const interval = moveToTarget(rotation, rotationTarget, setRotation);

      if (rotation === rotationTarget) {
        isMoving.current = false;
        clearInterval(interval);
      }
      return () => clearInterval(interval);
    }
  }, [rotationTarget, rotation, isMoving, maxRotation, setRotation]);

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        position: 'fixed',
        top: 0,
        left: 0,
        zIndex: 4,
        touchAction: 'none',
      }}
      ref={controlRef}
      onMouseDown={(e) => mouseDownEv(e, mouseDown, mouseStart)}
      onTouchStart={(e) => touchStartEv(e, mouseDown, mouseStart)}
      onMouseUp={() => mouseUpEv(mouseDown)}
      onTouchEnd={() => touchEndEv(mouseDown)}
      onWheel={(e) => {
        /*
        wheelEv(
          e,
          setRotation,
          isMoving,
          touchSense,
          rotation,
          maxRotation,
          isBeingAnimated
        );*/
      }}
      onMouseOut={() => mouseOutEv(mouseDown, mouseStart)}
      onMouseMove={(e) =>
        throttle(
          () =>
            mouseMoveEv(
              e,
              setLevel,
              setRotation,
              mouseDown,
              mouseStart,
              maxRotation
            ),
          timeout
        )
      }
    ></div>
  );
}
