import { Vector3, Euler } from 'three';

export type Component360Type = {
  frames: number;
  dir: string;
  zooms: number;
  levels: number;
  file: string;
  hotspots?: HotspotType3D[];
};

export type ComponentArrType = {
  frames: number;
  dir: string;
  zoom: number;
  level: number;
};

export type ImageJSONType = {
  _id: string;
  url: string;
};

export type NewImageType = { [key: string]: string };

//ADD NEW ITEMS TO THE END! Otherwise it will break the JSON that already exists
export enum Page {
  Version,
  Landing,
  Knee,
  Knee360,
  Knee360External,
  Ankle,
  HealthyJoint,
  ClinicalBleed,
  ClinicalBleedAnimation,
  ClinicalBleedAfterBleed360,
  AfterTheBleed,
  AfterTheBleedExternal,
  AfterBleed360External,
  ImpactOfSublclinicalBleeds,
  ProtectingJointHealth,
  Elocta,
  Alprolix,
  Home,
  One,
  JointInMotion,
  Synovitis,
  SynovitisAnimation,
  Synovitis360,
  SynovitisPostAnimation,
  SynovitisExternal,
  Synovitis360External,
  Arthropathy,
  ArthropathyAnimation,
  ArthropathyPost,
  Arthropathy360,
  ArthropathyExternal,
  ArthropathyExternal360,
  SubclinicalBleed,
  SubclinicalBleedAnimation,
  SubclinicalAfterBleed,
  SubclinicalExterior,
  AfterTheBleedSubclinical,
  AfterBleedAnimation,
  AfterBleedExternalSubclinical,
  AfterBleed360Subclinical,
  AfterBleed360SubclinicalExternal,
  SynovitisSubclinical,
  SynovitisSubclinical360,
  ArthropathySubclinical,
  ArthropathySubclinical360,
  AnkleVersion,
  AnkleLanding,
  AnkleKnee,
  AnkleKnee360,
  AnkleKnee360External,
  AnkleAnkle,
  AnkleHealthyJoint,
  AnkleClinicalBleed,
  AnkleClinicalBleedAnimation,
  AnkleClinicalBleedAfterBleed360,
  AnkleAfterTheBleed,
  AnkleAfterTheBleedExternal,
  AnkleAfterBleed360External,
  AnkleImpactOfSublclinicalBleeds,
  AnkleProtectingJointHealth,
  AnkleElocta,
  AnkleAlprolix,
  AnkleHome,
  AnkleOne,
  AnkleJointInMotion,
  AnkleSynovitis,
  AnkleSynovitisAnimation,
  AnkleSynovitis360,
  AnkleSynovitisPostAnimation,
  AnkleSynovitisExternal,
  AnkleSynovitis360External,
  AnkleArthropathy,
  AnkleArthropathyAnimation,
  AnkleArthropathyPost,
  AnkleArthropathy360,
  AnkleArthropathyExternal,
  AnkleArthropathyExternal360,
  AnkleSubclinicalBleed,
  AnkleSubclinicalBleedAnimation,
  AnkleSubclinicalAfterBleed,
  AnkleSubclinicalExterior,
  AnkleAfterTheBleedSubclinical,
  AnkleAfterBleedAnimation,
  AnkleAfterBleedExternalSubclinical,
  AnkleAfterBleed360Subclinical,
  AnkleAfterBleed360SubclinicalExternal,
  AnkleSynovitisSubclinical,
  AnkleSynovitisSubclinical360,
  AnkleArthropathySubclinical,
  AnkleArthropathySubclinical360,
  Hcp,
  Disclaimer,
}

export type PageType = {
  page: Page;
  visible: boolean;
  transitionFrame: number;
  animateOut?: boolean;
  backEvent?: PageType;
  themeNumber?: number;
};

export enum Joints {
  Knee,
  Ankle,
}

export type HotspotType = {
  pos: [number, number];
  color?: string;
  label?: string;
  clickEvent?: () => void;
};

export type HotspotType3D = {
  pos: [number, number, number];
  color?: string;
  label?: string;
  targetRotation?: number;
  clickEvent?: () => void;
  opacity?: number;
  visible?: number[];
  start?: number;
  end?: number;
  cmsId?: string;
};

export type StoryboardType = {
  _id: number;
  pos?: Vector3;
  rot?: Euler;
  scale?: number;
  stopPoint?: boolean;
  img: string;
  subtitle?: string;
  timePeriod?: { title?: string; step?: number; divisible?: number };
  allowControls?: boolean;
  loop?: boolean;
  hotspots?: HotspotType3D[];
  video?: string;
  bg?: string;
};
