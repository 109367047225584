import { fileDirectory } from '../cmsConfig';
import { checkServer } from './serverStatus';
import { setDataNoServer } from './setDataNoServer';
export const setData = async (
  content: any,
  hotspotFile: any,
  page: any,
  selectedLanguage: string,
  type: any,
  exportJSON: any,
  setExportJSON: any
) => {
  const status = await checkServer(fileDirectory);

  if (status.status !== 200) {
    return setDataNoServer(
      hotspotFile,
      page,
      content,
      type,
      exportJSON,
      setExportJSON
    );
  }

  let payload = {
    file: hotspotFile,
    content: content.content,
    textContent: content.textContent,
    page: page,
    type: content.type,
    label: content.label,
    media: content.media,
    language: selectedLanguage,
    hoverLabel: content.hoverLabel,
  };
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(payload),
  };
  fetch(`${fileDirectory}/api`, requestOptions).then((response) =>
    response.json()
  );
};
