import styled from 'styled-components';

export const FooterWrapper = styled.div<{
  isOpen?: boolean;
  theme: any;
  openMenu?: boolean;
}>`
  transition: max-height 0.5s;
  width: 100%;
  position: fixed;
  bottom: 0;
  max-height: ${(props) => (props.openMenu ? '60vh' : '30px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
`;
export const FooterContent = styled.div<{
  isOpen?: boolean;
  theme: any;
}>`
  max-height: ${(props) => (props.isOpen ? 'calc(100vh - 60px)' : '0px')};
  position: fixed;
  bottom: 30px;
  width: 100%;
  z-index: 3;
  transition: all 0.5s;
  box-sizing: border-box;
  padding: ${(props) => '0 10vw'};
  color: ${(props) => props.theme.brand.menuNoHoverText};
  overflow-y: auto;
  overflow-x: hidden;
  background: ${(props) => props.theme.gradient.primary};
`;

export const FooterWrapperInner = styled.div<{
  isOpen?: boolean;
  theme: any;
}>`
  width: calc(100%);
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  margin-top: -32px;
`;

export const FooterToggle = styled.button<{
  isOpen?: boolean;
}>`
  transition: color 0.2s;
  color: #fff;
  background: none;
  border: none;
  font-size: 18px;
  margin-right: 20px;
  position: absolute;
  top: -2px;
  right: 0px;
  transition: transform 2s;
  cursor: pointer;
  transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : 'rotate(0deg)')};
  &:hover {
    color: #000;
  }
`;
