import React, { useRef } from 'react';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { throttle } from '../utils/throttle';
import { themeColors } from '../styles';

export function RotationBar(props: {
  setRotation: any;
  rotation: number;
  maxRotation: number;
  levels?: number;
  direction: string;
}) {
  const { setRotation, rotation, maxRotation, direction, levels } = props;
  const timeout = useRef<any>(undefined);

  if (direction === 'horizontal') {
    return (
      <div
        style={{
          position: 'fixed',
          zIndex: 100,
          width: '40%',
          left: '30%',
          overflow: 'hidden',
          height: 30,
          bottom: 40,
        }}
      >
        <Slider
          min={0}
          max={maxRotation}
          value={rotation}
          step={1}
          onChange={(value) => {
            throttle(() => setRotation(value), timeout);
          }}
          style={{ margin: 10, width: '100%', boxSizing: 'border-box' }}
          trackStyle={{
            backgroundColor: themeColors.white.primary,
            height: '1px',
          }}
          railStyle={{
            backgroundColor: themeColors.grey.primary,
            height: '1px',
          }}
          handleStyle={{
            background: themeColors.brand.primary,
            border: `none`,
            boxShadow: `0 0 5px rgba(0,0,0,0.3)`,
            borderRadius: 0,
            width: 30,
            opacity: 1,
          }}
        />
      </div>
    );
  } else {
    return (
      <div
        style={{
          position: 'fixed',
          zIndex: 100,
          right: 10,
          top: '30%',
          overflow: 'hidden',
          width: 20,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onClick={() => setRotation}
      >
        <Slider
          min={0}
          vertical={true}
          max={levels || 2}
          value={rotation}
          step={1}
          onChange={(value) => {
            setRotation(value);
          }}
          style={{ height: '30vw', boxSizing: 'border-box' }}
          trackStyle={{
            backgroundColor: themeColors.white.primary,
            width: '1px',
          }}
          railStyle={{
            backgroundColor: themeColors.grey.primary,
            width: '1px',
          }}
          handleStyle={{
            background: themeColors.brand.primary,
            border: `none`,
            boxShadow: `0 0 5px rgba(0,0,0,0.3)`,
            borderRadius: 0,
            height: 30,
            opacity: 1,
          }}
        />
      </div>
    );
  }
}
