import { StoryboardType } from '../../../types';

export const storyboardAfterTheBleed = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: maxRotation,
    img: `${appAssets}/clinicalBleedAnim/afterBleed.jpg`,
  });
  return storyboardArr;
};
