import styled from 'styled-components';
import alprolixBG from './assets/alprolix.jpg';

export const EloctaBG = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: ${`url(${alprolixBG})`};
  background-size: cover;
  background-position: center;
`;
