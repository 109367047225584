import React from 'react';

export function Wrapper(props: { children: any; showControls: boolean }) {
  const { children, showControls } = props;
  return (
    <div
      style={{
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        display: showControls ? 'flex' : 'none',
        padding: 10,
        margin: 10,
        marginLeft: 0,
        backgroundColor: 'white',
        borderRadius: '0px 10px 10px 10px',
        maxHeight: 'calc(100% - 20px)',
        overflow: 'auto',
        maxWidth: 'calc(100% - 40px)',
        transition: 'all 2s',
        position: 'relative',
      }}
    >
      {children}
    </div>
  );
}
