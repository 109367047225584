import React from 'react';
import rotateIcon from '../../assets/icons/rotate.svg';
export function RotateScreen(props: { theme: any; shouldRender: boolean }) {
  const { shouldRender, theme } = props;

  if (!shouldRender) {
    return null;
  }

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        height: '100vh',
        width: '100vw',
        background: theme.grey.black,
        opacity: '0.9',
        zIndex: 1000,
        color: theme.white.primary,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      <img
        src={rotateIcon}
        style={{ width: 300, position: 'absolute' }}
        alt="Rotate Screen"
      />
    </div>
  );
}
