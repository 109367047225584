import React, { useMemo } from 'react';
import { Frames } from '../../components';
import { Page } from '../../types';
import {
  storyboardAfterTheBleed,
  storyboardAfterTheBleedAnimation,
  storyboardAfterTheBleedExternal,
} from '../../data/ankle/afterBleed';
import { RightMenuOne } from '../../menu/RightMenuOne';
import { LeftMenuOne } from '../../menu/LeftMenuOne';
import { PageType } from '../../types';
import { getTrackImages } from '../../utils/getTrackImages';
import { Frames360New } from '../../components/Frames360New';
import { shouldRender, shouldFadeOut } from '../../utils/fadeAndVisible';
import { Fade } from '../../components';

export function AfterBleed(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
}) {
  const { changePage, pageArray } = props;

  const memoProps: any = useMemo(() => {
    return [
      [
        pageArray[0].page === Page.AfterTheBleed,
        pageArray[0].page === Page.AfterTheBleed
          ? pageArray[0].animateOut
          : false,
      ],
      [
        pageArray[0].page === Page.AfterBleedAnimation,
        pageArray[0].animateOut &&
          pageArray[0].page === Page.AfterBleedAnimation,
      ],
      [pageArray[0].page === Page.ClinicalBleedAfterBleed360],
      [pageArray[0].page === Page.AfterTheBleedExternal],
      [
        pageArray[0].page === Page.AfterTheBleedExternal,
        pageArray[0].animateOut &&
          pageArray[0].page === Page.AfterTheBleedExternal,
      ],
      [pageArray[0].page === Page.AfterBleed360External],
    ];
  }, [pageArray]);
  return (
    <>
      {useMemo(() => {
        const thisPage = Page.AfterTheBleed;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardAfterTheBleed(1),
                changePage
              )}
              background="#444"
              animateOut={memoProps[0][1]}
              storyboard={storyboardAfterTheBleed(1)}
              rightMenu={<RightMenuOne setPage={changePage} page={thisPage} />}
              leftMenu={<LeftMenuOne setPage={changePage} page={thisPage} />}
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
              id="after-the-bleed"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.AfterBleedAnimation;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardAfterTheBleed(1),
                changePage
              )}
              animateOut={memoProps[1][1]}
              storyboard={storyboardAfterTheBleedAnimation(1)}
              isVideo={true}
              closeMenuEvent={() =>
                changePage({
                  page: Page.AfterTheBleed,
                  visible: true,
                  transitionFrame: -1,
                  animateOut: false,
                })
              }
              id="clinical-bleed"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.ClinicalBleedAfterBleed360;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[2][0]}
              config={{
                file: 'Orbit_Ankle_Clinical_Zoom',
                zooms: 2,
                levels: 1,
                frames: 59,
                dir: 'ankle/clinicalBleed',
              }}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.ClinicalBleedAfterBleed360}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.AfterTheBleed,
                  visible: true,
                  transitionFrame: 0,
                  animateOut: true,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.AfterTheBleedExternal;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardAfterTheBleedExternal(),
                changePage
              )}
              animateOut={false}
              storyboard={storyboardAfterTheBleedExternal()}
              closeMenuEvent={() =>
                changePage({
                  page: Page.AfterTheBleed,
                  visible: true,
                  transitionFrame: -1,
                  animateOut: false,
                })
              }
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.AfterTheBleedExternal}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.AfterTheBleedExternal}
                />
              }
              id="after-bleed-external"
            />
          </Fade>
        );
      }, [changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.AfterBleed360External;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[5][0]}
              config={{
                file: 'Orbit_Ankle_Clinical_Zoom',
                zooms: 2,
                levels: 1,
                frames: 59,
                dir: 'ankle/clinicalBleedExternal',
              }}
              external={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.AfterBleed360External}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.AfterTheBleedExternal,
                  visible: true,
                  transitionFrame: 0,
                  animateOut: true,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}
    </>
  );
}
