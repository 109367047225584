import React from 'react';
import { RoundButtonStyle, RoundButtonWrapperStyle } from './styles';
import { ThemeContext } from '../../context';
export const LeftMenuButton360 = (props: {
  title: string;
  onClickEvent?: () => void;
  isActive: boolean;
}) => {
  const { title, onClickEvent, isActive } = props;
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <RoundButtonWrapperStyle isActive={isActive} theme={theme}>
          <RoundButtonStyle
            onClick={() => (onClickEvent ? onClickEvent() : null)}
          >
            {title}
          </RoundButtonStyle>
        </RoundButtonWrapperStyle>
      )}
    </ThemeContext.Consumer>
  );
};
