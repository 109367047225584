import { StoryboardType } from '../../../types';

export const storyboardAfterTheBleedExternal = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: 1,
    img: `${appAssets}/ankle/clinicalBleedExternal/zoom0Level1/Orbit_Ankle_Clinical_Zoom1_0000.jpg`,
  });

  return storyboardArr;
};
