import React from 'react';

export const Column = (props: {
  children: React.ReactNode;
  style?: any;
  a?: string;
  j?: string;
}) => {
  const { a, j } = props;
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: a ? a : 'center',
        justifyContent: j ? j : 'space-between',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  );
};
