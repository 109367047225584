import React from 'react';
import { ThemeContext, ContentContext } from '../../../context';
import parse from 'html-react-parser';
import addIcon from '../../../assets/icons/hotspotPlus.svg';
import { FooterButtonStyled } from './styles';
export function FooterButton(props: {
  title: string;
  file: string;
  clickEvent: () => void;
  isOpen: boolean;
}) {
  const { title, clickEvent, isOpen, file } = props;
  return (
    <ContentContext.Consumer>
      {({ cmsData, editButton }) => {
        return (
          <ThemeContext.Consumer>
            {({ theme }) => (
              <button
                onClick={() => clickEvent()}
                style={{
                  background: 'none',
                  border: 'none',
                  cursor: 'pointer',
                }}
              >
                {cmsData.footerButtons.file}
                <FooterButtonStyled theme={theme}>
                  {editButton(file, 'footerButtons')}
                  <div
                    style={{
                      fontWeight: isOpen ? 'bold' : '',
                      fontSize: '12px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {parse(title)}
                  </div>
                  <img
                    src={addIcon}
                    style={{
                      width: 32,
                      transform: isOpen ? 'rotate(45deg)' : 'rotate(0deg)',
                      filter: theme.brand.invert,
                    }}
                    alt="add"
                  />
                </FooterButtonStyled>
              </button>
            )}
          </ThemeContext.Consumer>
        );
      }}
    </ContentContext.Consumer>
  );
}
