import React, { useMemo } from 'react';
import { Frames } from '../../components';
import { Page } from '../../types';
import { storyboardAfterTheBleed } from '../../data/knee/subclinicalBleed';
import { RightMenuOne } from '../../menu/RightMenuOne';
import { LeftMenuOne } from '../../menu/LeftMenuOne';
import { PageType } from '../../types';
import { getTrackImages } from '../../utils/getTrackImages';
import { Frames360New } from '../../components/Frames360New';

import { shouldRender, shouldFadeOut } from '../../utils/fadeAndVisible';
import { Fade } from '../../components';

export function AfterBleedSubclinical(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
}) {
  const { changePage, pageArray } = props;

  const memoProps: any = useMemo(() => {
    return [
      [
        pageArray[0].page === Page.AfterTheBleedSubclinical,
        pageArray[0].page === Page.AfterTheBleedSubclinical
          ? pageArray[0].animateOut
          : false,
      ],
      [pageArray[0].page === Page.AfterBleed360Subclinical],
      [pageArray[0].page === Page.AfterBleed360SubclinicalExternal],
    ];
  }, [pageArray]);

  return (
    <>
      {useMemo(() => {
        const thisPage = Page.AfterTheBleedSubclinical;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              background="#4d4d4d"
              images={getTrackImages(
                () => storyboardAfterTheBleed(),
                changePage
              )}
              animateOut={memoProps[0][1]}
              storyboard={storyboardAfterTheBleed()}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.AfterTheBleedSubclinical}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.AfterTheBleedSubclinical}
                />
              }
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
              id="after-the-bleed-subclinical"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.AfterBleed360Subclinical;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[1][0]}
              config={{
                file: 'OrbitKnee_SubClinicalBone_Zoom',
                zooms: 2,
                levels: 2,
                frames: 59,
                dir: 'subclinicalorbiteinternal',
              }}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.AfterBleed360Subclinical}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.AfterTheBleedSubclinical,
                  visible: true,
                  transitionFrame: -1,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.AfterBleed360SubclinicalExternal;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[2][0]}
              config={{
                file: 'OrbitKnee_HealthySkin_Zoom',
                zooms: 2,
                levels: 2,
                frames: 59,
                dir: 'healthyorbitexternal',
              }}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.AfterBleed360SubclinicalExternal}
                />
              }
              external={true}
              closeMenuEvent={() =>
                changePage({
                  page: Page.AfterTheBleedSubclinical,
                  visible: true,
                  transitionFrame: -1,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}
    </>
  );
}
