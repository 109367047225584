import styled from 'styled-components';

export const RightMenuStyle = styled.div<{
  bg?: string;
}>`
  transition: opacity 1s;
  position: fixed;
  display: flex;
  z-index: 10;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  right: 0px;
  height: 100vh;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
  opacity: 1;
  width: 400px;
`;
