import { getJSON } from './functions/getJSON';

import { themeColors } from '../styles';
//export const fileDirectory = 'https://secret-demo.herokuapp.com';
//export const fileDirectory = 'https://dev-medicine.wearecassette-dev.com';

//MODIFY THIS: The CMS URI if running on a node server, or with Node and React. If running without Node then leave blank
export const fileDirectory = 'http://localhost:3001';
//MODIFY THIS: The location of the original JSON content.
export const themeColor = themeColors.brand.primary;
export const cmsPassword = 'Admin123';

export async function sourceContent(originalJSON: any): Promise<any> {
  const sourceJSON = await getJSON(originalJSON);
  return sourceJSON;
}

export function sourceContentJS(hotspots: any): any {
  return hotspots;
}

export async function definedHotspots(
  sourceJSON: any
): Promise<{ file: string; position: number[] }[]> {
  const hotspotKeys = Object.keys(sourceJSON).map((key) => key);
  return hotspotKeys.map((key) => {
    return { file: key, position: [0, 0, 0] };
  });
}

export async function definedDoors(
  sourceJSON: any
): Promise<{ file: string }[]> {
  const doorKeys = Object.keys(sourceJSON.doors).map((key) => key);
  return doorKeys.map((key) => {
    return { file: key };
  });
}
