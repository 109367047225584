import importAllMap from './importImagesMap';
import { ImageJSONType, StoryboardType } from '../types';

export function getTrackImages(storyboard: any, changePage?: any) {
  const TRACK: ImageJSONType[] = importAllMap(
    storyboard().map((item: StoryboardType) => {
      return {
        _id: item._id,
        img: item.img,
      };
    })
  );
  return TRACK;
}
