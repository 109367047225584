import { ImageJSONType } from '../types';

export default function importAllMap(r: any) {
  let images: ImageJSONType[] = [];
  r.forEach((item: { _id: string; img: string }) => {
    images.push({
      _id: item._id,
      url: item.img,
    });
  });

  return images;
}
