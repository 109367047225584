import { StoryboardType } from '../../../types';

export const arthropathyPost = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: 0,
    img: `${appAssets}/ankle/arthropathy/zoom0Level1/Orbit_Ankle_Arthropothy_Zoom_1_0000.jpg`,
  });
  return storyboardArr;
};
