import React from 'react';

export function Label(props: { str: string; w?: string | number }) {
  const { str, w } = props;
  return (
    <label
      style={{
        fontSize: '12px',
        marginLeft: 2,
        marginTop: 8,
        fontWeight: 'bold',
        width: w,
        minWidth: w,
      }}
    >
      {str}
    </label>
  );
}
