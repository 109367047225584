import { StoryboardType } from '../../../types';

export const storyboardAfterTheBleed = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: maxRotation,
    img: `${appAssets}/ankle/clinicalBleed/zoom0Level1/Orbit_Ankle_Clinical_Zoom1_0000.jpg`,
  });
  return storyboardArr;
};
