import React from 'react';
import {
  RectangleButtonStyle,
  RectangleButtonSquareStyle,
  RectangleButtonWrapperStyle,
  RectangleButtonWrapperInnerStyle,
} from './styles';
import { ThemeContext } from '../../context';
import threeDIcon from '../../assets/icons/360.svg';

export const LeftMenuButton = (props: {
  title: string;
  onClickEvent?: () => void;
  editButton?: any;
}) => {
  const { title, onClickEvent, editButton } = props;
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <RectangleButtonWrapperStyle clickable={onClickEvent ? true : false}>
          {editButton && editButton}
          <RectangleButtonWrapperInnerStyle
            onClick={() => (onClickEvent ? onClickEvent() : null)}
          >
            <RectangleButtonStyle
              clickable={onClickEvent ? true : false}
              theme={theme}
            >
              {title}
            </RectangleButtonStyle>
            {onClickEvent && (
              <RectangleButtonSquareStyle theme={theme}>
                <img src={threeDIcon} style={{ width: 32 }} alt="play" />
              </RectangleButtonSquareStyle>
            )}
          </RectangleButtonWrapperInnerStyle>
        </RectangleButtonWrapperStyle>
      )}
    </ThemeContext.Consumer>
  );
};
