import React, { useMemo } from 'react';
import { Frames } from '../../components';
import { Page } from '../../types';
import { synovitisSubclinical } from '../../data/knee/synovitisSubclinical';
import { RightMenuOne } from '../../menu/RightMenuOne';
import { LeftMenuOne } from '../../menu/LeftMenuOne';
import { PageType } from '../../types';
import { getTrackImages } from '../../utils/getTrackImages';
import { Frames360New } from '../../components/Frames360New';

import { shouldRender, shouldFadeOut } from '../../utils/fadeAndVisible';
import { Fade } from '../../components';

export function SynovitisSubclinical(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
}) {
  const { changePage, pageArray } = props;

  const memoProps: any = useMemo(() => {
    return [
      [
        pageArray[0].page === Page.SynovitisSubclinical,
        pageArray[0].page === Page.SynovitisSubclinical
          ? pageArray[0].animateOut
          : false,
      ],
      [pageArray[0].page === Page.SynovitisSubclinical360],
    ];
  }, [pageArray]);

  return (
    <>
      {useMemo(() => {
        const thisPage = Page.SynovitisSubclinical;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(() => synovitisSubclinical(), changePage)}
              animateOut={memoProps[0][1]}
              storyboard={synovitisSubclinical()}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.SynovitisSubclinical}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.SynovitisSubclinical}
                />
              }
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    visible: true,
                    transitionFrame: -1,
                    animateOut: false,
                  }),
                targetRotation: 0,
              }}
              id="synovitis-subclinical"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.SynovitisSubclinical360;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[1][0]}
              config={{
                file: 'OrbitKnee_SynovitisSkin_Zoom',
                zooms: 2,
                levels: 2,
                frames: 59,
                dir: 'synovitisorbitexternal',
              }}
              external={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.SynovitisSubclinical360}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.SynovitisSubclinical,
                  visible: true,
                  transitionFrame: -1,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}
    </>
  );
}
