import React from 'react';
import { themeColors } from '../styles';
import closeIcon from '../assets/icons/close.svg';
export function CloseButton(props: {
  onClickEvent: () => void;
  isVisible: boolean;
}) {
  const { onClickEvent, isVisible } = props;

  if (!isVisible) return null;

  return (
    <button
      style={{
        background: themeColors.brand.primary,
        zIndex: 100,
        border: 'none',
        position: 'fixed',
        top: 50,
        left: 50,
        width: 30,
        height: 30,
        color: themeColors.white.primary,
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onClick={() => {
        onClickEvent();
      }}
    >
      <img src={closeIcon} style={{ width: '100%' }} alt="close" />
    </button>
  );
}
