import { Vector3, Euler } from 'three';
import { StoryboardType } from '../../../types';

export const storyboard360 = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  for (let i = 0; i < maxRotation; i++) {
    storyboardArr.push({
      _id: i,
      img: `${appAssets}/zoomtoknee/${i}.jpg`,
      allowControls: false,
      loop: true,
      bg: '#454545',
      pos: new Vector3(-1.2, 0, 0),
      rot: new Euler(0, 0, 0),
      scale: 50,
      hotspots: [
        {
          cmsId: 'tibia',
          opacity: i > 20 && i < 36 ? 0 : 1,
          pos: [0.02, 0.05, 0.02],
          label: 'Tibia',
        },
        {
          cmsId: 'fibula',
          opacity: i > 36 && i < 56 ? 0 : 1,
          pos: [-0.04, 0, -0.02],
          label: 'Fibula',
        },
        {
          cmsId: 'talus',
          opacity: i < 10 || i > 46 ? 1 : 0,
          pos: [0.02, -0.05, 0.03],
          label: 'Talus',
        },
        {
          cmsId: 'calcaneus',
          opacity: i > 7 && i < 50 ? 1 : 0,
          pos: [-0.02, -0.1, -0.09],
          label: 'Calcaneus',
        },
      ],
      video: `${appAssets}/zoomToKnee.mp4`,
    });
  }

  return storyboardArr;
};
