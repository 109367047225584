import React from 'react';
import { PageType } from '../types';
import { ContentContext } from '../context';
import { ThemeContext } from '../context';
import { MenuButtonStyle, ChildMenuButtonStyle } from './styles';
import fullscreen from '../assets/icons/fullscreen.svg';
import { menuJsonToArray } from '../utils/menuJsonToArray';

import parse from 'html-react-parser';
export function Menu(props: {
  menuItems: any[];
  page: PageType;
  setMenuPage: any;
  visible: boolean;
  setDeviceType: (event: string) => void;
  isMobile: boolean;
  shouldRender: boolean;
}) {
  //PROPS
  const { page, setMenuPage, isMobile, menuItems, shouldRender } = props;

  //STATES
  const [isOpen, setIsOpen] = React.useState(-1);

  //CONSTS
  const parentArr = menuJsonToArray(menuItems).filter((item) => !item.parent);

  //FUNCTIONS
  if (!shouldRender) {
    return null;
  }

  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <div
          style={{
            position: 'fixed',
            top: isMobile ? 0 : 30,
            zIndex: 100,
            display: 'flex',
          }}
        >
          {parentArr.map((item: any, index: number) => {
            const childIsActive = menuJsonToArray(menuItems).filter(
              (subItem) =>
                page.page === subItem.id && subItem.parent === item.id
            );

            return (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                }}
                key={`menu-itemw-${index}`}
              >
                <ContentContext.Consumer>
                  {({ cmsData, editButton }) => editButton(item.file, 'menu')}
                </ContentContext.Consumer>
                <MenuButtonStyle
                  key={`menu-item-${index}`}
                  theme={theme}
                  isActive={page.page === item.id || childIsActive.length > 0}
                  onClick={() => {
                    setMenuPage({
                      page: item.id,
                      visible: true,
                      transitionFrame: -1,
                      themeNumber: item.themeNumber,
                    });
                  }}
                  onMouseEnter={() => setIsOpen(item.id)}
                  onMouseLeave={() => setIsOpen(-1)}
                >
                  {parse(item.label)}
                </MenuButtonStyle>
                <ContentContext.Consumer>
                  {({ cmsData, editButton, cmsIsEnabled }) => (
                    <>
                      {menuJsonToArray(menuItems)
                        .filter(
                          (subItem) =>
                            subItem.parent &&
                            subItem.parent === item.id &&
                            subItem.visible !== false
                        )
                        .map((subItem: any, subIndex: number) => {
                          const isOpenSub = isOpen === item.id || cmsIsEnabled;

                          return (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                              }}
                              key={`subMenu-${index}-${subIndex}`}
                            >
                              {editButton(subItem.file, 'menu')}

                              <ChildMenuButtonStyle
                                isActive={page.page === subItem.id}
                                isOpen={isOpenSub}
                                theme={theme}
                                key={`menu-item-${index}-${subItem.id}`}
                                onClick={() => {
                                  setMenuPage({
                                    page: subItem.id,
                                    visible: true,
                                    transitionFrame: -1,
                                    themeNumber: item.themeNumber,
                                  });
                                }}
                                onMouseEnter={() => {
                                  setIsOpen(item.id);
                                }}
                                onMouseLeave={() => {
                                  setIsOpen(-1);
                                }}
                              >
                                {subItem.label}
                              </ChildMenuButtonStyle>
                            </div>
                          );
                        })}
                    </>
                  )}
                </ContentContext.Consumer>
              </div>
            );
          })}
          <div
            style={{
              position: 'fixed',
              zIndex: 12,
              top: 16,
              right: 0,
              height: 26,
              width: 50,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              padding: 4,
            }}
          >
            <img
              src={fullscreen}
              alt="fullscreen"
              title="Fullscreen"
              style={{
                height: 40,

                opacity: 0.8,
                borderRadius: '50%',
                cursor: 'pointer',
                background: 'grey',
              }}
              onClick={() => {
                if (document.fullscreenElement) {
                  document.exitFullscreen();
                } else {
                  document.body.requestFullscreen();
                }
              }}
            />
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
}
