import React, { useEffect, useRef, useCallback } from 'react';
import { Timeline } from '../';
import { Wrapper, Canvas } from './styles';
export const FrameImageNew = (props: {
  images: string[];
  rotation: number;
  setRotation: (rotation: number) => void;
  storyboardRef?: any;
  thumbnails?: string[];
  isVideo?: boolean;
  autoAnimate?: boolean;
  model?: string;
  hotspotEvent?: any;
  visible?: boolean;
}) => {
  const {
    rotation,
    images,
    thumbnails,
    isVideo,
    autoAnimate,
    storyboardRef,
    visible,
    setRotation,
  } = props;

  const playerRef = useRef<any>();
  const canRef = useRef<any>();
  const timeoutRef = useRef<any>();
  const [duration, setDuration] = React.useState(0);
  const [videoPlaying, setVideoPlaying] = React.useState(false);
  const [scroll, setScroll] = React.useState(0);

  const getCurrentTime: any = useCallback(() => {
    if (playerRef && playerRef.current) {
      timeoutRef.current = requestAnimationFrame(() => getCurrentTime());
      const canvas = canRef.current;
      canvas.style.height = '100vh';
      canvas.width = canvas.offsetWidth;
      canvas.height = canvas.offsetHeight;
      setRotation(playerRef.current.currentTime);
      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.drawImage(playerRef.current, 0, 0, canvas.width, canvas.height);
      }
    }
  }, [setRotation]);

  useEffect(() => {
    if (!visible && playerRef && playerRef.current) {
      timeoutRef.current = requestAnimationFrame(() => getCurrentTime());
      return () => cancelAnimationFrame(timeoutRef.current);
    }
  }, [playerRef, visible, autoAnimate, getCurrentTime]);

  useEffect(() => {
    if (!visible && playerRef && playerRef.current) {
      playerRef.current.currentTime = 0;
    }
  }, [playerRef, visible]);

  useEffect(() => {
    if (playerRef && playerRef.current) {
      if (videoPlaying) {
        playerRef.current.play();
      } else {
        playerRef.current.pause();
      }
    }
  }, [videoPlaying, playerRef]);

  useEffect(() => {
    if (scroll > 0 && playerRef && playerRef.current) {
      timeoutRef.current = requestAnimationFrame(
        () => (playerRef.current.currentTime = scroll)
      );
    }
    setScroll(0);
  }, [scroll, playerRef]);

  useEffect(() => {
    if (images.length > 0 && canRef && canRef.current) {
      const canvas = canRef.current;
      canvas.style.height = '100vh';
      canvas.width = 1920;
      canvas.height = 1080;
      const ctx = canvas.getContext('2d');
      if (ctx && images[Math.ceil(rotation)]) {
        ctx.drawImage(
          images[Math.ceil(rotation)],
          0,
          0,
          canvas.width,
          canvas.height
        );
      }
    }
  }, [rotation, images]);

  return (
    <Wrapper>
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '100vh',
          width: '100vw',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
        }}
        draggable="false"
      >
        <Canvas ref={canRef} />
        {isVideo ? (
          <>
            <video
              ref={playerRef}
              src={storyboardRef[0].video}
              autoPlay={true}
              style={{
                display: 'none',
              }}
              playsInline={true}
              onEnded={() => {
                setVideoPlaying(false);
              }}
              muted={true}
              onCanPlayThrough={() => {
                if (autoAnimate) setVideoPlaying(true);
                if (autoAnimate) playerRef.current.play();
                setDuration(playerRef.current.duration);
              }}
            />
          </>
        ) : (
          <>
            {thumbnails &&
              !images &&
              thumbnails.map((item, index) => {
                return (
                  <img
                    key={`Two-${index}-${Math.random()}`}
                    src={thumbnails[rotation + 1]}
                    style={{
                      position: 'absolute',
                      height: '100%',
                      margin: 'auto',
                      zIndex: 1,
                      background: 'transparent',
                    }}
                    alt="Img One"
                  />
                );
              })}
          </>
        )}
        {duration > 0 && storyboardRef[0].allowControls ? (
          <Timeline
            setScroll={setScroll}
            setRotation={setRotation}
            rotation={rotation}
            maxRotation={duration}
            playAnimation={setVideoPlaying}
            animationPlaying={videoPlaying}
            storyboard={storyboardRef}
          />
        ) : null}
      </div>
    </Wrapper>
  );
};
