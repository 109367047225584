import React from 'react';
import { themeColors } from '../styles';
import WarningIcon from '@mui/icons-material/Warning';

export function WarningBar() {
  // This is to prevent the warning bar from showing up in the public version
  if (process.env.REACT_APP_CMS === 'false') {
    return null;
  }
  return (
    <div
      style={{
        zIndex: 300,
        background: themeColors.grey.primary,
        position: 'fixed',
        top: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: themeColors.white.primary,
        padding: 10,
        boxSizing: 'border-box',
        margin: 'auto',
      }}
    >
      <WarningIcon sx={{ color: themeColors.brand.primary }} />
      CMS Version - Do not deploy to public server
      <WarningIcon sx={{ color: themeColors.brand.primary }} />
    </div>
  );
}
