import { Page } from '../types';

export function getHotspotPages(hotspots: any, page: Page) {
  const hotspotsForThisPage = [];
  const hotspotsArray = Object.keys(hotspots);

  for (const hotspot of hotspotsArray) {
    const shouldDisplay = hotspots[hotspot].page === page;
    if (shouldDisplay) {
      hotspotsForThisPage.push(hotspots[hotspot]);
    }
  }
  return hotspotsForThisPage;
}
