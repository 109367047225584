import styled from 'styled-components';

export const CTA = styled.button<{
  theme: any;
}>`
  color: ${(props) => props.theme.brand.primary};
  border: none;
  background: none;
  font-size: 1.5rem;
  opacity: 1;
  animation-name: example2;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  max-width: 200px;
  @keyframes example2 {
    0% {
      ${(props) => `text-shadow: 0px 0px 0px ${props.theme.brand.primary}`};
    }
    75% {
      ${(props) => `text-shadow: 0px 0px 25px ${props.theme.brand.primary}`};
    }
    100% {
      ${(props) => `text-shadow: 0px 0px 0px ${props.theme.brand.primary}`};
    }
  }
`;
export const RectangleButtonWrapperStyle = styled.div<{
  offset?: number[];
  clickable: boolean;
  white?: boolean;
  isOpen: boolean;
  theme: any;
}>`
  transition: opacity 1s;
  background: ${(props) => (props.white ? '#fff' : props.theme.white.primary)};
  margin-top: ${(props) => (props.white ? '0px' : '0px')};
  min-width: 150px;
  padding: 0px;
  width: 100%;
  max-width: 90%;
  opacity: 1;
  z-index: 3;
  margin-left: -24px;
  display: flex;
  position: relative;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  color: ${(props) => (props.clickable ? '#fff' : '#222')};
`;

export const RectangleButtonWrapperInnerStyle = styled.button<{}>`
  display: flex;
  position: relative;
  padding: 0;
  background: none;
  width: 100%;
  height: 100%;
  border: none;
`;

export const RectangleButtonSquareStyle = styled.div<{
  white?: boolean;
  theme: any;
  isOpen: boolean;
}>`
  padding: 0px 10px 0px 10px;
  background: ${(props) => (props.isOpen ? '#c0d72f' : '#c0d72f')};
  color: ${(props) =>
    props.isOpen ? props.theme.brand.primary : props.theme.brand.primary};
  display: flex;
  height: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
`;

export const RectangleButtonSquareInnerStyle = styled.div<{
  isOn?: boolean;
}>`
  transition: transform 1s;
  transform: ${(props) => (props.isOn ? 'rotate(0deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const RectangleButtonStyle = styled.div<{
  offset?: number[];
  clickable: boolean;
  white?: boolean;
  isOpen: boolean;
  theme: any;
}>`
  transition: opacity 1s;
  height: 100%;
  width: 100%;
  background: ${(props) =>
    props.isOpen ? props.theme.brand.primary : props.theme.brand.primary};
  color: ${(props) =>
    props.isOpen ? props.theme.white.primary : props.theme.white.primary};
  border: none;
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 10px;
  box-sizing: border-box;
  text-align: left;
  cursor: pointer;
`;

export const ContentPanelStyle = styled.div<{
  isOpen: boolean;
  fullscreen: boolean;
}>`
  box-sizing: border-box;
  transition: max-height 0.5s;
  overflow: hidden;
  width: ${(props) => (props.fullscreen ? '100vw' : '80vw')};
  background: ${(props) => (props.fullscreen ? 'rgba(0,0,0,0.8)' : '')};
  height: ${(props) => (props.fullscreen ? 'calc(100vh )' : '100%')};
  margin: ${(props) => (props.fullscreen ? '0px' : '0 0 0 -202px')};
  padding: ${(props) => (props.fullscreen ? '80px 0 80px 0' : '0')};
  z-index: ${(props) => (props.fullscreen ? 100000 : '0')};
  max-height: ${(props) =>
    props.fullscreen ? '100vh' : props.isOpen ? '50vh' : '0vh'};
  position: ${(props) => (props.fullscreen ? 'fixed' : 'relative')};
  top: ${(props) => (props.fullscreen ? '0' : '0')};
  left: ${(props) => (props.fullscreen ? 0 : '')};
  align-self: flex-end;
  margin-right: 60px;
`;

export const ContentPanelInnerStyle = styled.div<{
  isOpen: boolean;
  theme: any;
}>`
  background: ${(props) => props.theme.white.primary};
  width: 100%;
  box-sizing: border-box;
  transition: max-height 0.5s;
  max-height: ${(props) => (props.isOpen ? '100%' : '0vh')};
  height: 100%;
  z-index: 2;
  padding: 20px;
  overflow: auto;
  sup {
    font-size: 0.6em;
  }
  ol {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
    font-size: 0.6em;
  }
`;
