import React, { useRef, useEffect, Suspense } from 'react';
import { Vector3 } from 'three';
import { FullCentralWrapper } from '../styles';
import {
  FrameImageNew,
  CloseButton,
  Subtitle,
  BackButton,
  RotationBar,
  CircleComponent,
} from '.';
import { Canvas } from '@react-three/fiber';
import Model from './Model';
import {
  mouseDownEv,
  touchStartEv,
  mouseUpEv,
  touchEndEv,
  mouseOutEv,
  mouseMoveEv,
  touchMoveEv,
} from '../utils/mouseEvents';
import { throttle } from '../utils/throttle';
import { StoryboardType, Component360Type } from '../types';
import { loadImage } from '../utils/loadImage';
import { backEvent } from '../utils/backEvent';
import { DeviceContext, ContentContext } from '../context';
import { Euler } from 'three';
import { LeftMenu360One } from '../menu/LeftMenu360One';
import { createUseGesture, dragAction, pinchAction } from '@use-gesture/react';

const useGesture = createUseGesture([dragAction, pinchAction]);

export function Frames360New(props: {
  storyboard?: StoryboardType[];
  model?: string;
  isVideo?: boolean;
  autoAnimate?: boolean;
  rightMenu?: JSX.Element;
  leftMenu?: JSX.Element;
  closeMenuEvent?: () => void;
  backMenuEvent?: { event: () => void; targetRotation: number };
  animateOut?: boolean;
  id?: string;
  rotateElement?: JSX.Element;
  is360?: boolean;
  circle?: boolean;
  transitionFrame?: number;
  visible?: boolean;
  background?: string;
  config: Component360Type;
  external?: boolean;
}) {
  //PROPS
  const {
    model,
    storyboard,
    rightMenu,
    isVideo,
    closeMenuEvent,
    backMenuEvent,
    autoAnimate,
    circle,
    visible,
    background,
    config,
    external,
  } = props;

  //STATE
  const [rotation, setRotation] = React.useState(0);
  const [imgsLoaded, setImgsLoaded] = React.useState<any[]>([]);
  const [zoom, setZoom] = React.useState(0);
  const [level, setLevel] = React.useState(1);
  const [levelAdjusted, setLevelAdjusted] = React.useState(1);
  const [storyboardRef, setStoryboardRef] = React.useState<any>(null);

  //REFS
  const mouseDown = useRef(false);
  const isBeingControlled = useRef(false);
  const isBeingAnimated = useRef(false);
  const mouseStart = useRef({ x: 0, y: 0 });
  const timeout = useRef<any>(undefined);
  const canvas = useRef(null);

  //CONSTS
  const maxRotation = storyboard ? storyboard.length - 1 : 59;

  //FUNCTIONS
  function hotspotEvent(targetRotation: number, onClickEvent: () => void) {
    isBeingAnimated.current = true;
    isBeingControlled.current = false;
    onClickEvent();
  }

  //REACT HOOKS
  useEffect(() => {
    const lAdjusted = level <= 0.75 ? 0 : level <= 1.25 ? 1 : 2;
    setLevelAdjusted(lAdjusted);
  }, [level]);

  useEffect(() => {
    const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

    const storyboardArr: StoryboardType[] = [];
    const levelStr =
      levelAdjusted === 0 ? '_High' : levelAdjusted === 1 ? '' : '_Low';
    for (let i = 0; i < 60 + 1; i++) {
      const frameNumber =
        i < 10 ? `000${i}` : i < 100 ? `00${i}` : i < 1000 ? `0${i}` : `${i}`;

      storyboardArr.push({
        allowControls: true,
        _id: i,
        img: `${appAssets}/${config.dir}/zoom${zoom}Level${levelAdjusted}/${
          config.file
        }${zoom + 1}${levelStr}_${frameNumber}.jpg`,
        rot: new Euler(0, i / 10, 0),
        hotspots: storyboard && storyboard[i] ? storyboard[i].hotspots : [],
        scale: storyboard && storyboard[i] ? storyboard[i].scale : 1,
        pos:
          storyboard && storyboard[i]
            ? storyboard[i].pos
            : new Vector3(0, 0, 0),
      });
    }

    setStoryboardRef(storyboardArr);
  }, [config, levelAdjusted, zoom, storyboard]);

  useEffect(() => {
    if (storyboardRef) {
      Promise.all(
        storyboardRef.map((image: any) =>
          loadImage({ url: image.img, _id: image._id })
        )
      )
        .then((url) => {
          setImgsLoaded(url);
        })
        .catch((err) => console.log('Failed to load images', err));
    }
  }, [storyboardRef]);

  //OTHER HOOKS
  useGesture(
    {
      onDrag: ({ pinching, cancel, offset: [x, y], ...rest }) => {
        if (pinching) return cancel();
      },
      onPinch: ({
        origin: [ox, oy],
        first,
        movement: [ms],
        offset: [s, a],
        memo,
      }) => {
        if (ms > 1) {
          setZoom && setZoom(1);
        } else {
          setZoom && setZoom(0);
        }
      },
    },
    {
      target: canvas,
      pinch: { scaleBounds: { min: 0.5, max: 2 }, rubberband: true },
    }
  );

  if (!storyboardRef) {
    return null;
  }
  return (
    <DeviceContext.Consumer>
      {(device) => {
        const isMobile = device === 'mobile';
        return (
          <>
            <BackButton
              onClickEvent={
                backMenuEvent
                  ? () => {
                      backEvent(
                        backMenuEvent.event,
                        isBeingAnimated,
                        isBeingControlled
                      );
                    }
                  : () => null
              }
              visible={backMenuEvent && !isMobile ? true : false}
            />

            <FullCentralWrapper
              style={{ background: background ? background : '' }}
            >
              <CircleComponent isVisible={circle || false} />
              <CloseButton
                isVisible={closeMenuEvent ? true : false}
                onClickEvent={
                  closeMenuEvent
                    ? () => {
                        backEvent(
                          closeMenuEvent,
                          isBeingAnimated,
                          isBeingControlled
                        );
                      }
                    : () => null
                }
              />
              {!external && (
                <LeftMenu360One
                  setZoom={setZoom}
                  zoom={zoom}
                  level={level}
                  setLevel={setLevel}
                />
              )}
              {rightMenu}
              {!external && (
                <RotationBar
                  setRotation={setLevel}
                  rotation={levelAdjusted}
                  maxRotation={2}
                  direction="vertical"
                  levels={config.levels}
                />
              )}
              {!isVideo && storyboardRef.length > 1 && (
                <RotationBar
                  setRotation={setRotation}
                  rotation={rotation}
                  maxRotation={maxRotation}
                  direction="horizontal"
                  levels={config.levels}
                />
              )}
              <div
                style={{
                  width: '100%',
                  height: '100vh',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  zIndex: 2,
                  touchAction: 'none',
                }}
                onMouseDown={(e: any) =>
                  mouseDownEv ? mouseDownEv(e, mouseDown, mouseStart) : null
                }
                onTouchStart={(e: any) =>
                  touchStartEv
                    ? throttle(
                        () => touchStartEv(e, mouseDown, mouseStart),
                        timeout
                      )
                    : null
                }
                onMouseUp={() => (mouseUpEv ? mouseUpEv(mouseDown) : null)}
                onTouchEnd={() => (touchEndEv ? touchEndEv(mouseDown) : null)}
                onMouseOut={() =>
                  mouseOutEv ? mouseOutEv(mouseDown, mouseStart) : null
                }
                onMouseMove={(e: any) => {
                  throttle(
                    () =>
                      mouseMoveEv(
                        e,
                        () => null,
                        setRotation,
                        mouseDown,
                        mouseStart,
                        maxRotation
                      ),
                    timeout
                  );
                }}
                onTouchMove={(e: any) => {
                  touchMoveEv(
                    e,
                    mouseStart,
                    maxRotation,
                    setRotation,
                    setLevel
                  );
                }}
              >
                <ContentContext.Consumer>
                  {({ cmsData, editButton }) =>
                    model && (
                      <div
                        style={{
                          background: 'red',
                          position: 'fixed',
                          zIndex: 10000,
                          top: 100,
                          left: 100,
                        }}
                      >
                        <Canvas
                          ref={canvas}
                          style={{
                            width: '100%',
                            height: '100vh',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            zIndex: 2,
                          }}
                          gl={{ preserveDrawingBuffer: true }}
                          camera={{ fov: 45, position: [0, 0, 28] }}
                        >
                          <Suspense fallback={null}>
                            <group
                              position={storyboardRef[Math.floor(rotation)].pos}
                              rotation={storyboardRef[Math.floor(rotation)].rot}
                              scale={storyboardRef[Math.floor(rotation)].scale}
                            >
                              {model && (
                                <Model
                                  model={model}
                                  hotspots={
                                    level === 1 &&
                                    zoom === 0 &&
                                    storyboardRef[rotation].hotspots
                                  }
                                  hotspotEvent={hotspotEvent}
                                  cmsData={cmsData}
                                />
                              )}
                            </group>
                          </Suspense>
                        </Canvas>
                      </div>
                    )
                  }
                </ContentContext.Consumer>
              </div>
              <FrameImageNew
                images={imgsLoaded}
                rotation={rotation}
                setRotation={() => null}
                isVideo={isVideo}
                storyboardRef={storyboardRef}
                autoAnimate={autoAnimate}
                visible={visible}
              />

              <Subtitle
                fileName={storyboardRef[Math.floor(rotation)].subtitle}
                isVisible={storyboardRef[Math.floor(rotation)].subtitle}
              />
            </FullCentralWrapper>
          </>
        );
      }}
    </DeviceContext.Consumer>
  );
}
