import React, { useRef } from 'react';
import { SaveButton } from './components';
import { Label } from './components/Label';
import { Divider } from './components/Divider';
import { TextArea } from './components/TextArea';
import { LabelTitle } from './components/LabelTitle';
import { ImgInputs } from './components/ImgInputs';

import CheckButton from './components/CheckButton';
import { Editor } from '@tinymce/tinymce-react';

export const HotspotFields = (props: {
  hotspotMap: any;
  setSelectedHotspot: any;
  selectedHotspot: any;
  isVisible: any;
  content: any;
  setContent: any;
  saveEvent: any;
  saved: any;
  setSaved: any;
}) => {
  const {
    selectedHotspot,
    isVisible,
    content,
    setContent,
    saveEvent,
    saved,
    setSaved,
  } = props;

  const [showPreview, setShowPreview] = React.useState('wys');
  const editorRef = useRef<any>();

  if (!isVisible) return null;
  return (
    <>
      {content.type === 'mix-content' || content.type === 'dynamic' ? (
        <>
          <ImgInputs
            setContent={setContent}
            content={content}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            saveEvent={saveEvent}
          />
        </>
      ) : (
        content.type !== 'html-text' && (
          <>
            <Label str="Content" />
            <input
              style={{
                background: 'none',
                border: 'none',
                width: '100%',
              }}
              value={content.media}
              placeholder="Media: https://yourimage.com"
              onChange={(e) =>
                setContent({ ...content, media: e.target.value })
              }
            />
            <SaveButton
              title="Save"
              saved={saved}
              setSaved={setSaved}
              onClickEvent={() => saveEvent()}
            />
          </>
        )
      )}
      {content.type === 'html-text' && selectedHotspot.type !== 'doors' && (
        <div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }}
          >
            <Label str="Editor:" w={100} />
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
              <CheckButton
                title="RICH TEXT"
                onClickEvent={() => setShowPreview('wys')}
                selected={showPreview === 'wys'}
              />

              <CheckButton
                title="HTML"
                onClickEvent={() => setShowPreview('html')}
                selected={showPreview === 'html'}
              />
            </div>
          </div>
          <Divider />
          <div
            style={{
              background: '#2b283d',
              padding: 10,
              boxSizing: 'border-box',
            }}
          >
            <LabelTitle content={content} setContent={setContent} />
            {showPreview === 'wys' ? (
              <div>
                <Editor
                  apiKey="qvh76c7734yj3ptv8a3bx6qyt4fkethac5fk2amkdqmodb8l"
                  onInit={(evt, editor) => (editorRef.current = editor)}
                  initialValue={content.content}
                  init={{
                    height: 500,
                    menubar: false,
                    content_style: 'body { background: #2b283d;color:#fff; }',
                    plugins: ['lists', 'code'],
                    toolbar: [
                      'bold italic | bullist numlist superscript subscript outdent indent | alignleft aligncenter alignright alignjustify',
                    ],
                  }}
                />
                <SaveButton
                  title="Save"
                  saved={saved}
                  setSaved={setSaved}
                  onClickEvent={() => {
                    if (editorRef.current) {
                      const newContent = {
                        ...content,
                        content: editorRef.current.getContent(),
                      };
                      saveEvent(newContent);
                    } else {
                      saveEvent(content);
                    }
                  }}
                />
              </div>
            ) : (
              <>
                <TextArea
                  content={content.content}
                  onChangeEvent={(e) =>
                    setContent({ ...content, content: e.target.value })
                  }
                />
                <SaveButton
                  title="Save"
                  saved={saved}
                  setSaved={setSaved}
                  onClickEvent={() => saveEvent()}
                />
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};
