import styled from 'styled-components';

export const ContentPanel = styled.div`
  font-size: '1rem';
  margin: 0;
  padding: 10px;
  color: #fff;
  font-family: museo-sans-display, sans-serif;
  font-weight: 900;
  pointer-events: none;
  user-select: none;
  sup {
    font-size: 0.6em;
  }
`;
