import React from 'react';
import { BackButtonStyle } from './styles';
import back from '../assets/icons/home.svg';
import { ContentContext, ComponentContext, ThemeContext } from '../context';

export const BackButton = (props: {
  onClickEvent: () => void;
  visible: boolean;
}) => {
  if (!props.visible) return null;
  return (
    <ComponentContext.Consumer>
      {(componentContext) => {
        return (
          <ThemeContext.Consumer>
            {({ theme }) => (
              <ContentContext.Consumer>
                {({ cmsData, editButton }) => {
                  return (
                    <BackButtonStyle>
                      {editButton(cmsData.otherItems.back.file, 'otherItems')}
                      <img
                        src={back}
                        style={{
                          width: 126,
                        }}
                        alt={cmsData.otherItems.back.label}
                        onClick={() => props.onClickEvent()}
                      />
                      {componentContext.ankleKnee && (
                        <div
                          style={{
                            position: 'fixed',
                            background: theme.brand.primary,
                            borderRadius: '50%',
                            width: 8,
                            height: 8,
                            top:
                              componentContext.ankleKnee === 'knee' ? 106 : 120,
                            left:
                              componentContext.ankleKnee === 'knee' ? 66 : 52,
                          }}
                        ></div>
                      )}
                      <h6 style={{ color: '#fff', margin: 4, fontWeight: 300 }}>
                        {cmsData.otherItems.back.label}
                      </h6>
                    </BackButtonStyle>
                  );
                }}
              </ContentContext.Consumer>
            )}
          </ThemeContext.Consumer>
        );
      }}
    </ComponentContext.Consumer>
  );
};
