import { Vector3, Euler } from 'three';
import { StoryboardType } from '../types';

export const storyboardMan = (
  maxRotation: number,
  quality: string | null,
  setPage: any
) => {
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  const storyboardArr: StoryboardType[] = [];
  for (let i = 0; i < maxRotation; i++) {
    const frameNumber =
      i < 10 ? `000${i}` : i < 100 ? `00${i}` : i < 1000 ? `0${i}` : `${i}`;

    storyboardArr.push({
      _id: i,
      pos: new Vector3(-0.2, 2.2),
      rot: new Euler(0, i / 10, 0),
      scale: 10,
      img: `${appAssets}/orbitMan/${
        quality ? quality : ''
      }OrbitMan_${frameNumber}.jpg`,
      subtitle: 'WelcomeSubtitles',
      allowControls: true,
      loop: true,
      bg: '#444444',
      hotspots: [
        {
          opacity: i < 10 || i > 20 ? 1 : 0,
          pos: [0.15, -0.62, 0.05],
          color: '#ed9903',
          targetRotation: 0,
          clickEvent: () => {
            setPage('knee');
          },
        },
        {
          opacity: i > 40 && i < 48 ? 0 : 1,
          pos: [-0.15, -1.0, 0.05],
          color: '#ed9903',
          targetRotation: 0,
          clickEvent: () => {
            setPage('ankle');
          },
        },
      ],
    });
  }
  return storyboardArr;
};
