import React from 'react';
import { LeftMenu, LeftMenuButton } from '../components';
import { Page } from '../types';
import { ContentContext } from '../context';
import { getHotspotPages } from '../utils/getHotspotPages';

export function LeftMenuOne(props: {
  setPage: React.Dispatch<React.SetStateAction<any>>;
  page: Page;
  transition?: number;
}) {
  const { setPage, page } = props;

  return (
    <ContentContext.Consumer>
      {({ cmsData, editButton }) => {
        return (
          <LeftMenu>
            {getHotspotPages(cmsData.leftMenu, page).map((item, index) => {
              const config = item.config;

              const newPageConfig = {
                page: config.setPage,
                transitionFrame: config.transitionFrame,
                animateOut: config.animateOut,
                backEvent: config.backEvent,
                visible: config.visible,
              };

              return (
                <LeftMenuButton
                  title={item.label}
                  onClickEvent={() =>
                    setPage({
                      page: newPageConfig.page,
                      visible: true,
                      transitionFrame: newPageConfig.transitionFrame,
                    })
                  }
                  key={`index-menu-${index}`}
                  editButton={editButton(item.file, 'leftMenu')}
                />
              );
            })}
          </LeftMenu>
        );
      }}
    </ContentContext.Consumer>
  );
}
