import React, { useEffect, useCallback } from 'react';
import { getData, setData } from './functions';
import { CMButton, Wrapper, Divider, CMSWrapper, Tab } from './components';
import { languages } from './languages';
import { HotspotFields } from './HotspotFields';
import { cmsPassword, sourceContentJS, definedHotspots } from './cmsConfig';
import { defaultValues } from './const';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { downloadFile } from './functions';
import { themeColors } from '../styles';
import { SelectedHotspotType } from './types/hotspotType';
import { HotspotContext } from './context';
import { editButton } from './components/editButton';
import closeIcon from '../assets/icons/close.svg';

const nullSelectedDefault = { file: null, page: null, type: null, index: null };
export default function CMS(props: {
  authReq: boolean;
  dataInJSON: any;
  devMode: boolean;
  enabled: boolean;
  children?: (
    setHotspot: any,
    cmsData: any,
    editButton: any,
    cmsIsEnabled: boolean
  ) => React.ReactNode;
  theme: any;
}) {
  const { authReq, children, enabled, dataInJSON, devMode } = props;

  const [contentMaps, setContentMaps] = React.useState<any>(null);
  const [exportJSON, setExportJSON] = React.useState<any>(null);
  const [selectedHotspot, setSelectedHotspot] =
    React.useState<SelectedHotspotType>(nullSelectedDefault);
  const [copied, setCopied] = React.useState(false);
  const [showControls, setShowControls] = React.useState(false);
  const [configOpen, setConfigOpen] = React.useState(0);
  const [authPassed, setAuthPassed] = React.useState(false);
  const [saved, setSaved] = React.useState(0);
  const [password, setPassword] = React.useState('');
  const [content, setContent] = React.useState(defaultValues);

  const selectedLanguage = languages[0];
  const allData = useCallback(
    (dataInJSON: any) => {
      const newJSON: any = {};
      if (!devMode) {
        for (let j = 0; j < dataInJSON.length; j++) {
          const dataStub = dataInJSON[j];
          const dataStubKeys = Object.keys(dataStub);
          const parentKey = dataStubKeys[0];
          newJSON[parentKey] = {};
          const dataStubKeysTwo = Object.keys(dataStub[dataStubKeys[0]]);

          for (let i = 0; i < dataStubKeysTwo.length; i++) {
            const fileId = dataStubKeysTwo[i];
            if (fileId) {
              newJSON[parentKey][fileId] = dataStub[parentKey][fileId];
            }
          }
        }
        return newJSON;
      } else {
        const newJSON: any = {};
        for (let j = 0; j < dataInJSON.length; j++) {
          const dataStub = dataInJSON[j];
          const dataStubKeys = Object.keys(dataStub);
          newJSON[dataStubKeys[0]] = {};
          for (let i = 0; i < dataStub[dataStubKeys[0]].length; i++) {
            const fileId = dataStub[dataStubKeys[0]][i].file;

            if (fileId) {
              newJSON[dataStubKeys[0]][fileId] = dataStub[dataStubKeys[0]][i];
            }
          }
        }

        return newJSON;
      }
    },
    [devMode]
  );

  function saveContent(contentIn: any) {
    setData(
      contentIn,
      selectedHotspot ? selectedHotspot.file : null,
      selectedHotspot ? selectedHotspot.page : null,
      selectedLanguage,
      selectedHotspot.type,
      exportJSON,
      setExportJSON
    );
    setContent(contentIn);
    setSaved(saved + 1);
    // setConfigOpen(2);
  }

  useEffect(() => {
    selectedHotspot &&
      selectedHotspot.file &&
      getData(
        selectedHotspot.file,
        selectedHotspot.page,
        setContent,
        selectedLanguage,
        selectedHotspot.type,
        exportJSON
      );
  }, [selectedHotspot, selectedLanguage, allData, exportJSON]);

  useEffect(() => {
    const sourceContentReturn = sourceContentJS(allData(dataInJSON));
    definedHotspots(sourceContentReturn).then((hotspots) => {
      setContentMaps({ hotspots });
    });

    setExportJSON(sourceContentReturn);
  }, [allData, dataInJSON]);

  useEffect(() => {
    password === cmsPassword && !authPassed && setAuthPassed(true);
  }, [password, authPassed]);

  const exportToJson = (e: any) => {
    e.preventDefault();
    downloadFile(JSON.stringify(exportJSON), 'assets.json', 'text/json');
  };

  if (!authPassed && authReq) {
    return (
      <CMSWrapper>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={{ margin: 10, width: 100 }}
          placeholder="CMS Password"
          type="password"
        />
      </CMSWrapper>
    );
  }

  if (!contentMaps) {
    return null;
  }

  if (!selectedHotspot) {
    return null;
  }
  return (
    <HotspotContext.Provider value={{ selectedHotspot, setSelectedHotspot }}>
      <CMSWrapper>
        {enabled && (
          <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CMButton
                showControls={selectedHotspot.file !== null || showControls}
                setShowControls={setShowControls}
              />
            </div>

            <Wrapper
              showControls={selectedHotspot.file !== null || showControls}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  width: '100%',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <img
                        src={closeIcon}
                        alt="close"
                        style={{
                          filter: 'invert(1)',
                          width: 20,
                          height: 20,
                          cursor: 'pointer',
                        }}
                        onClick={() => {
                          setSelectedHotspot(nullSelectedDefault);
                          setShowControls(false);
                        }}
                      />
                    </div>
                  </div>
                  {configOpen === 0 ? (
                    <>
                      <HotspotFields
                        isVisible={true}
                        content={content}
                        setContent={setContent}
                        setSelectedHotspot={setSelectedHotspot}
                        selectedHotspot={selectedHotspot}
                        hotspotMap={contentMaps.hotspots}
                        saveEvent={saveContent}
                        saved={saved}
                        setSaved={setSaved}
                      />
                      <Tab
                        title={'Publish'}
                        onClickEvent={
                          saved > 0 ? () => setConfigOpen(2) : () => null
                        }
                        selected={saved > 0}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          position: 'fixed',
                          top: 0,
                          left: 0,
                          background: 'rgba(0,0,0,0.8)',
                          width: '100vw',
                          height: '100vh',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          zIndex: 100,
                        }}
                      >
                        <div
                          style={{
                            background: themeColors.white.primary,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textAlign: 'center',
                            border: `10px solid ${themeColors.brand.primary}`,
                            borderRadius: 10,
                            position: 'relative',
                          }}
                        >
                          <img
                            src={closeIcon}
                            alt="close"
                            style={{
                              filter: 'invert(1)',
                              cursor: 'pointer',
                              width: 20,
                              marginLeft: 'auto',
                            }}
                            onClick={() => setConfigOpen(0)}
                          />
                          <h5 style={{ margin: 10 }}>Download File</h5>
                          <h6 style={{ margin: 10 }}>
                            Please download the updated content file and provide
                            it to your deployment team
                          </h6>

                          <button
                            style={{
                              border: 'none',
                              width: 200,
                              margin: 3,
                              padding: '3px 10px 3px 10px',
                              borderRadius: 5,
                              cursor: 'pointer',
                              background: themeColors.gradient.primary,
                              color: themeColors.white.primary,
                            }}
                            type="button"
                            onClick={exportToJson}
                          >
                            Export
                          </button>

                          <Divider />

                          {exportJSON && (
                            <textarea
                              value={JSON.stringify(exportJSON)}
                              style={{
                                marginBottom: 10,
                                width: '80%',
                                maxWidth: '80%',
                                minHeight: 50,
                                height: 50,
                                opacity: 0.4,
                                border: 'none',
                                background: 'rgba(0,0,0,0.1)',
                                padding: 10,
                              }}
                            />
                          )}
                          <CopyToClipboard
                            onCopy={() => setCopied(true)}
                            text={JSON.stringify(exportJSON)}
                          >
                            <button
                              onClick={() => null}
                              style={{
                                border: 'none',
                                width: 200,
                                margin: 3,
                                padding: '3px 10px 3px 10px',
                                borderRadius: 5,
                                marginBottom: 20,
                                cursor: 'pointer',
                              }}
                            >
                              {copied ? 'Copied' : 'Copy to clipboard'}
                            </button>
                          </CopyToClipboard>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Wrapper>
          </>
        )}
        {children &&
          children(
            (hotspot: { file: string }) => {
              setSelectedHotspot({
                index: 0,
                file: hotspot.file,
                page: 0,
                type: 'hotspots',
              });
            },
            exportJSON,
            enabled ? editButton : () => {},
            enabled
          )}
      </CMSWrapper>
    </HotspotContext.Provider>
  );
}
