import React, { useMemo } from 'react';
import { Frames } from '../../components';
import { Page } from '../../types';
import { storyboard } from '../../data/knee/healthyJoint/storyboard';
import { storyboard360 } from '../../data/knee/healthyJoint/storyboard360';
import { storyboardJointInMotion } from '../../data/knee/healthyJoint/storyboardJointInMotion';
import { RightMenuOne } from '../../menu/RightMenuOne';
import { LeftMenuOne } from '../../menu/LeftMenuOne';
import { PageType } from '../../types';
import { getTrackImages } from '../../utils/getTrackImages';
import { Frames360New } from '../../components/Frames360New';
import { shouldRender, shouldFadeOut } from '../../utils/fadeAndVisible';
import { Fade } from '../../components';

export function HealthyJoint(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
}) {
  const { changePage, pageArray } = props;
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  const memoProps: any = useMemo(() => {
    return [
      [pageArray[0].page === Page.Knee, true],
      [pageArray[0].page === Page.JointInMotion, false],
      [pageArray[0].page === Page.Knee360],
    ];
  }, [pageArray]);

  return (
    <>
      {useMemo(() => {
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, Page.Knee)}
            shouldRender={shouldRender(pageArray, Page.Knee)}
          >
            <Frames
              visible={shouldFadeOut(pageArray, Page.Knee)}
              model={`${appAssets}/man/scene.gltf`}
              images={getTrackImages(() => storyboard(30), changePage)}
              animateOut={false}
              storyboard={storyboard(30)}
              transitionFrame={pageArray[0].transitionFrame}
              isVideo={true}
              rightMenu={
                <RightMenuOne setPage={changePage} page={Page.HealthyJoint} />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.HealthyJoint}
                  transition={pageArray[0].transitionFrame}
                />
              }
              autoAnimate={true}
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
              id="healthy-joint"
            />
          </Fade>
        );
      }, [changePage, pageArray, appAssets])}

      {useMemo(() => {
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, Page.JointInMotion)}
            shouldRender={shouldRender(pageArray, Page.JointInMotion)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardJointInMotion(40),
                changePage
              )}
              animateOut={memoProps[1][1]}
              storyboard={storyboardJointInMotion(40)}
              rightMenu={
                <RightMenuOne setPage={changePage} page={Page.JointInMotion} />
              }
              leftMenu={
                <LeftMenuOne setPage={changePage} page={Page.JointInMotion} />
              }
              autoAnimate={false}
              isVideo={true}
              closeMenuEvent={() =>
                changePage({
                  page: Page.Knee,
                  visible: true,
                  transitionFrame: 0,
                  animateOut: true,
                })
              }
              id="healthy-joint-in-motion"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, Page.Knee360)}
            shouldRender={shouldRender(pageArray, Page.Knee360)}
          >
            <Frames360New
              animateOut={memoProps[1][1]}
              storyboard={storyboard360(60)}
              autoAnimate={false}
              rightMenu={
                <RightMenuOne setPage={changePage} page={Page.Knee360} />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.Knee,
                  visible: true,
                  transitionFrame: -1,
                })
              }
              id="healthy-joint-in-motion"
              model={`${appAssets}/man/scene.gltf`}
              config={{
                file: 'OrbitKnee_HealthyBone_Zoom',
                zooms: 2,
                levels: 2,
                frames: 60,
                dir: 'healthyorbitinternal',
              }}
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray, appAssets])}
    </>
  );
}
