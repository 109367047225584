import React from 'react';
export function Fade(props: {
  visible: boolean;
  shouldRender?: boolean;
  children: any;
  zIndex?: number;
  style?: any;
  onClickEvent?: () => void;
}) {
  const { visible, children, zIndex, style, onClickEvent, shouldRender } =
    props;
  const [opacity, setOpacity] = React.useState(0);
  React.useEffect(() => {
    if (visible && opacity < 1) {
      setOpacity(1);
    } else if (!visible && opacity > 0) {
      setOpacity(0);
    }
  }, [visible, opacity]);

  if (shouldRender === false) return null;

  return (
    <>
      <div
        style={{
          opacity: opacity,
          zIndex: visible ? (zIndex ? zIndex : 1) : 0,
          transition: 'opacity 0.4s ease-in-out',
          ...style,
          position: 'fixed',
          top: 0,
          left: 0,
        }}
        onClick={() => (onClickEvent ? onClickEvent() : null)}
      >
        {children}
      </div>
    </>
  );
}
