import { StoryboardType } from '../../../types';

export const storyboardAfterTheBleedAnimation = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  for (let i = 0; i < maxRotation; i++) {
    storyboardArr.push({
      _id: i,
      img: `${appAssets}/track-0/${i}.jpeg`,
    });
  }
  return storyboardArr;
};
