import React from 'react';
import { Frames } from '../../components';
import { Page } from '../../types';
import { protectingJointHealth } from '../../data/knee/protectingJointHealth/protectingJointHealth';
import { getTrackImages } from '../../utils/getTrackImages';
import { PageType } from '../../types';
import { RightMenuOne } from '../../menu/RightMenuOne';
import { LeftMenuOne } from '../../menu/LeftMenuOne';
import { shouldRender, shouldFadeOut } from '../../utils/fadeAndVisible';
import { Fade } from '../../components';

export function ProtectingJointHealth(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
}) {
  const { changePage, pageArray } = props;

  return (
    <>
      <Fade
        visible={!shouldFadeOut(pageArray, Page.ProtectingJointHealth)}
        shouldRender={shouldRender(pageArray, Page.ProtectingJointHealth)}
      >
        <Frames
          images={getTrackImages(() => protectingJointHealth(1), changePage)}
          animateOut={false}
          storyboard={protectingJointHealth(1)}
          isVideo={false}
          rightMenu={
            <RightMenuOne
              setPage={changePage}
              page={Page.ProtectingJointHealth}
            />
          }
          leftMenu={
            <LeftMenuOne
              setPage={changePage}
              page={Page.ProtectingJointHealth}
            />
          }
          backMenuEvent={{
            event: () =>
              changePage({
                page: Page.Landing,
                visible: true,
                transitionFrame: -1,
                animateOut: false,
              }),
            targetRotation: 0,
          }}
          id="protecting-joint-health"
        />
      </Fade>
    </>
  );
}
