import React, { useState, useEffect } from 'react';
import { FooterWrapper, FooterWrapperInner, FooterContent } from './styles';
import { Row, Column } from '../';
import { FooterButton } from './FooterButton';
import { ThemeContext } from '../../context';
import parse from 'html-react-parser';
import cutout from '../../assets/cutout.svg';
import closeIcon from '../../assets/icons/close.svg';
import { themeInjection } from '../../utils/themeInjection';

const footerLinks = ['privacy', 'terms', 'cookie', 'contact'];
export function Footer(props: {
  page: any;
  icon: any;
  cmsData: any;
  editButton: any;
}) {
  const { page, icon, cmsData, editButton } = props;
  const [isOpen, setIsOpen] = useState<string | boolean>(false);
  const [openContent, setOpenContent] = useState<{
    content: string;
    label: string;
    page: string;
    file: string;
  }>({
    content: '',
    label: '',
    page: '',
    file: '',
  });

  useEffect(() => {
    const pageID = cmsData.footerButtons[page[0].page];
    let content = cmsData.footerButtons['noref'].content;
    let label = cmsData.footerButtons['noref'].label;
    let file = cmsData.footerButtons['noref'].file;
    if (pageID !== undefined) {
      content = pageID.content;
      label = pageID.label;
      file = pageID.file;
    }

    setOpenContent(
      content
        ? {
            content: content[0].content[0].html_content,
            label: label,
            page: pageID,
            file: file,
          }
        : { content: '', label: '', page: '', file: '' }
    );
  }, [page, cmsData]);

  function handleOpen(file: any, theme: any) {
    setIsOpen(file === isOpen ? false : true);
    const pageID = cmsData.footerButtons[file];
    let content = pageID.content;
    let label = pageID.label;

    setOpenContent(
      content
        ? {
            content: themeInjection(content[0].content[0].html_content, theme),
            label: label,
            page: pageID,
            file: file,
          }
        : { content: '', label: '', page: '', file: '' }
    );
  }

  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <FooterWrapper theme={theme}>
          <FooterContent isOpen={isOpen === true} theme={theme}>
            <Row a="flex-start" j="flex-start">
              <Column a="flex-start" j="flex-start">
                <img
                  src={closeIcon}
                  onClick={() => setIsOpen(false)}
                  style={{
                    height: 32,
                    marginTop: 18,
                    paddingLeft: 16,
                    paddingRight: 16,
                    cursor: 'pointer',
                    filter: theme.brand.invert,
                  }}
                  alt="close icons"
                />
              </Column>
              <div style={{ marginLeft: 100, marginRight: 100 }}>
                {parse(openContent.content)}
              </div>
            </Row>
          </FooterContent>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-start',
              background: isOpen === true ? theme.brand.primary : '',
            }}
          >
            <img src={cutout} alt="cutout" />
            <div
              style={{ width: '100%', background: theme.brand.primary }}
            ></div>
          </div>
          <FooterWrapperInner theme={theme}>
            <Row
              style={{
                overflow: 'hidden',
                height: '100%',
                width: '100%',
              }}
              a="flex-start"
            >
              {icon}
              <Row
                style={{
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                }}
                j="space-between"
              >
                <Row
                  style={{
                    width: '40%',
                    justifyContent: 'space-between',
                    marginLeft: 100,
                    fontSize: '12px',
                  }}
                  j="flex-start"
                >
                  {footerLinks.map((item: any, index: number) => {
                    return (
                      <div
                        style={{ position: 'relative' }}
                        key={`link_${index}`}
                      >
                        {editButton(item, 'footerItems')}
                        {cmsData.footerItems[item].label &&
                          parse(
                            themeInjection(
                              cmsData.footerItems[item].label,
                              theme
                            )
                          )}
                      </div>
                    );
                  })}
                </Row>
                <Row
                  style={{
                    width: '40%',
                  }}
                  j="flex-end"
                >
                  {cmsData.footerButtons[page[0].page] ? (
                    <FooterButton
                      file={cmsData.footerButtons[page[0].page].file}
                      title={
                        cmsData.footerButtons[page[0].page]
                          ? cmsData.footerButtons[page[0].page].label
                          : 'References'
                      }
                      isOpen={
                        isOpen === cmsData.footerButtons[page[0].page]
                          ? cmsData.footerButtons[page[0].page].file
                          : false
                      }
                      clickEvent={() =>
                        handleOpen(
                          cmsData.footerButtons[page[0].page].file,
                          theme
                        )
                      }
                    />
                  ) : (
                    <FooterButton
                      title={cmsData.footerButtons.noref.label}
                      file={cmsData.footerButtons.noref.file}
                      isOpen={isOpen === cmsData.footerButtons.noref.file}
                      clickEvent={() =>
                        handleOpen(cmsData.footerButtons.noref.file, theme)
                      }
                    />
                  )}
                  {cmsData.footerButtons.eloctapi && (
                    <FooterButton
                      title={cmsData.footerButtons.eloctapi.label}
                      file={cmsData.footerButtons.eloctapi.file}
                      isOpen={isOpen === cmsData.footerButtons.eloctapi.file}
                      clickEvent={() =>
                        handleOpen(cmsData.footerButtons.eloctapi.file, theme)
                      }
                    />
                  )}
                  {cmsData.footerButtons.alprolixpi && (
                    <FooterButton
                      title={cmsData.footerButtons.alprolixpi.label}
                      file={cmsData.footerButtons.alprolixpi.file}
                      isOpen={isOpen === cmsData.footerButtons.alprolixpi.file}
                      clickEvent={() =>
                        handleOpen(cmsData.footerButtons.alprolixpi.file, theme)
                      }
                    />
                  )}
                </Row>
              </Row>
            </Row>
          </FooterWrapperInner>
        </FooterWrapper>
      )}
    </ThemeContext.Consumer>
  );
}
