import React, { useEffect } from 'react';
import { HighlightTextStyle } from './styles';
import parse from 'html-react-parser';

export function Title(props: { title: string; editButton: any }) {
  const { title, editButton } = props;
  const [letterHighlight, setLetterHighlight] = React.useState(0);

  const highlightLetter = title.replace(/ /g, '-');
  const highlightLetterArr = highlightLetter.split('');

  useEffect(() => {
    let count = 0;
    const interval = setInterval(() => {
      count++;
      setLetterHighlight((letterHighlight) => letterHighlight + 1);
      if (count > 300) {
        clearInterval(interval);
      }
    }, 60);

    return () => clearInterval(interval);
  }, []);

  return (
    <h4
      style={{ padding: 20, margin: 0, display: 'flex', position: 'relative' }}
    >
      {editButton('chooseExperience', 'otherItems')}
      {highlightLetterArr.map((item, index) => (
        <HighlightTextStyle
          isHighlight={index}
          activate={letterHighlight < index ? false : true}
          key={`${index}-button`}
        >
          {item === '-' ? parse('&nbsp;') : item}
        </HighlightTextStyle>
      ))}
    </h4>
  );
}
