import React from 'react';
import { themeColor } from '../cmsConfig';
export function SaveButton(props: {
  title: any;
  onClickEvent: () => void;
  saved: any;
  setSaved: any;
}) {
  const { title, onClickEvent, saved, setSaved } = props;
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
      }}
    >
      <button
        onClick={() => {
          setSaved && setSaved(true);
          onClickEvent();
        }}
        style={{
          border: 'none',
          maxWidth: 200,
          margin: 3,
          padding: '3px 10px 3px 10px',
          borderRadius: 5,
          cursor: 'pointer',
          background: themeColor,
        }}
      >
        {saved ? 'Saved!' : title ? title : 'Save & Preview'}
      </button>
    </div>
  );
}
