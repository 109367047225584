import React from 'react';
export function CircleComponent(props: {
  lines?: boolean;
  isVisible: boolean;
}) {
  const { isVisible, lines } = props;

  if (!isVisible) return null;
  return (
    <div
      style={{
        height: '90vh',
        width: '90vh',
        margin: 'auto',
        zIndex: 2,
        borderRadius: '50%',
        border: '1px solid rgba(0,0,0,0.2)',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        opacity: 1,
        position: 'fixed',
      }}
    >
      {lines && (
        <div
          style={{
            marginTop: '-5vh',
            height: '90vh',
            width: '40vh',
            margin: 'auto',
            zIndex: 1,
            borderRadius: '50%',
            border: '1px dashed rgba(0,0,0,0.2)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              height: '40vh',
              minWidth: '90vh',
              margin: 'auto',
              zIndex: 1,
              borderRadius: '50%',
              border: '1px dashed rgba(0,0,0,0.2)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                height: '1px',
                minWidth: '90vh',
                margin: 'auto',
                zIndex: 1,
                borderBottom: '1px dashed rgba(0,0,0,0.2)',
              }}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}
