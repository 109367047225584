import React from 'react';

export function Divider() {
  return (
    <div
      style={{
        width: '100%',
        borderBottom: '1px solid #eee',
        paddingBottom: 10,
        marginBottom: 10,
      }}
    ></div>
  );
}
