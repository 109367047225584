export const languages = [
  'EN',
  'SP',
  'GE',
  'FR',
  'IT',
  'PO',
  'RU',
  'CH',
  'JP',
  'KR',
];
