import React from 'react';
import { FullCentralWrapper } from '../styles';
import splash from '../assets/splash-1.jpeg';
import { Fade } from '../components';
import { Page, PageType } from '../types';
import { ThemeContext, ContentContext } from '../context';
import { shouldRender, shouldFadeOut } from '../utils/fadeAndVisible';
import sobiOrange from '../assets/sobi_orange.svg';
import parse from 'html-react-parser';

export function HCP(props: {
  changePage: (thisPage: Page) => void;
  setDeviceType: (event: string) => void;
  isMobile: boolean;
  thisPage: Page;
  pageArray: PageType[];
  branded?: boolean;
}) {
  const { thisPage, pageArray, changePage, isMobile } = props;
  function clickStart() {
    changePage(thisPage);
  }

  return (
    <Fade
      visible={!shouldFadeOut(pageArray, thisPage)}
      shouldRender={shouldRender(pageArray, thisPage)}
    >
      <ThemeContext.Consumer>
        {({ theme }) => (
          <ContentContext.Consumer>
            {({ cmsData, editButton }) => {
              return (
                <FullCentralWrapper bg={theme.gradient.primary}>
                  <div
                    style={{
                      width: '100vw',
                      height: '100vh',
                      transition: 'filter 1s',
                      backgroundImage: `url(${splash})`,
                      backgroundSize: isMobile ? 'contain' : 'cover',
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                      opacity: 0.2,
                    }}
                  ></div>
                  <div
                    style={{
                      position: 'absolute',
                      color: '#222',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                      zIndex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ContentContext.Consumer>
                      {({ cmsData, editButton }) => {
                        return (
                          <div
                            style={{
                              background: '#fff',
                              maxWidth: '600px',
                              maxHeight: '80%',
                              width: '80%',
                              overflow: 'auto',
                              padding: 40,
                            }}
                          >
                            <img
                              src={sobiOrange}
                              alt=""
                              style={{ height: '40px', marginBottom: '20px' }}
                            />
                            <br />
                            <div style={{ position: 'relative' }}>
                              <b>
                                {editButton('hcp', 'otherItems')}
                                {cmsData.otherItems.hcp.label}
                              </b>
                            </div>
                            <div
                              style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                padding: 30,
                                boxSizing: 'border-box',
                              }}
                            >
                              <button
                                style={{
                                  background: '#333',
                                  padding: 10,
                                  minWidth: 120,
                                  border: 'none',
                                  color: '#fff',
                                  fontSize: '1.2rem',
                                  position: 'relative',
                                  marginRight: 10,
                                  cursor: 'pointer',
                                }}
                                onClick={() => clickStart()}
                              >
                                {editButton('hcpAccept', 'otherItems')}
                                {cmsData.otherItems.hcpAccept.label}
                              </button>
                              <button
                                style={{
                                  background: '#333',
                                  padding: 10,
                                  minWidth: 120,
                                  border: 'none',
                                  color: '#fff',
                                  fontSize: '1.2rem',
                                  position: 'relative',
                                  marginLeft: 10,
                                  cursor: 'pointer',
                                }}
                              >
                                {editButton('hcpPatient', 'otherItems')}
                                {parse(cmsData.otherItems.hcpPatient.label)}
                              </button>
                            </div>
                            <div
                              style={{
                                fontSize: '0.8rem',
                                fontWeight: 'bold',
                                position: 'relative',
                              }}
                            >
                              {editButton('hcpDisclaimer', 'otherItems')}
                              {parse(cmsData.otherItems.hcpDisclaimer.label)}
                            </div>
                          </div>
                        );
                      }}
                    </ContentContext.Consumer>
                  </div>
                </FullCentralWrapper>
              );
            }}
          </ContentContext.Consumer>
        )}
      </ThemeContext.Consumer>
    </Fade>
  );
}
