import React from 'react';
import parse from 'html-react-parser';
import { ContentPanel } from './styles';
import { themeColors } from '../../styles';
import { ContentContext } from '../../context';

export function Subtitle(props: { fileName: string; isVisible?: boolean }) {
  const { fileName, isVisible } = props;

  if (!isVisible) return null;

  return (
    <>
      <ContentContext.Consumer>
        {({ cmsData, editButton }) => {
          return (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '5vw',
                boxSizing: 'border-box',
                overflow: 'visible',
                zIndex: 2,
                position: 'relative',
                alignSelf: 'flex-start',
                userSelect: 'none',
                pointerEvents: 'none',
              }}
            >
              {/*
              <div
                style={{
                  position: 'fixed',
                  height: '100vh',
                  width: '30vw',
                  minWidth: 200,
                  padding: '5vw',
                  top: 0,
                  left: '-5vw',
                  background: 'rgba(0,0,0,0.2)',
                  filter: 'blur(40px)',
                  zIndex: -1,
                  pointerEvents: 'none',
                }}
              ></div>*/}
              <div
                style={{
                  maxWidth: 300,
                  position: 'relative',
                  userSelect: 'none',
                  pointerEvents: 'none',
                }}
              >
                <div
                  style={{
                    width: '30vw',
                    minWidth: 300,
                    maxWidth: 300,
                    padding: 10,
                    zIndex: -1,
                    userSelect: 'none',
                    pointerEvents: 'none',
                  }}
                >
                  {cmsData.subtitles[fileName].label !== '' && (
                    <h2
                      style={{
                        fontSize: '2rem',
                        padding: 10,
                        margin: 0,
                        color: themeColors.white.primary,
                        userSelect: 'none',
                        pointerEvents: 'none',
                      }}
                    >
                      {parse(cmsData.subtitles[fileName].label)}
                    </h2>
                  )}
                  {editButton(fileName, 'subtitles')}
                  <ContentPanel>
                    {parse(
                      cmsData.subtitles[fileName].content[0].content[0]
                        .html_content
                    )}
                  </ContentPanel>
                </div>
              </div>
            </div>
          );
        }}
      </ContentContext.Consumer>
    </>
  );
}
