import styled from 'styled-components';
import eloctaBG from '../../assets/elocta.png';

export const EloctaBG = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: ${`url(${eloctaBG})`};
  background-size: cover;
  background-position: center;
`;
