import React from 'react';
import { LeftMenu, LeftMenuButton360 } from '../components';

export function LeftMenu360One(props: {
  setZoom?: React.Dispatch<React.SetStateAction<any>>;
  zoom: number;
  setLevel?: React.Dispatch<React.SetStateAction<any>>;
  level: number;
}) {
  const { setZoom, zoom } = props;
  return (
    <LeftMenu>
      <LeftMenuButton360
        title="x0.5"
        isActive={zoom === 0}
        onClickEvent={() => (setZoom ? setZoom(0) : null)}
      />
      <LeftMenuButton360
        title="x1.0"
        isActive={zoom === 1}
        onClickEvent={() => (setZoom ? setZoom(1) : null)}
      />
      {/*

      <LeftMenuButton360
        title="1"
        isActive={level === 0}
        onClickEvent={() => (setLevel ? setLevel(0) : null)}
      />
      <LeftMenuButton360
        title="2"
        isActive={level === 1}
        onClickEvent={() => (setLevel ? setLevel(1) : null)}
      />
      <LeftMenuButton360
        title="3"
        isActive={level === 2}
        onClickEvent={() => (setLevel ? setLevel(2) : null)}
  />*/}
    </LeftMenu>
  );
}
