import styled from 'styled-components';

export const FooterButtonStyled = styled.div<{
  theme: any;
}>`
  color: ${(props) => props.theme.brand.menuNoHoverText};
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
`;
