import styled from 'styled-components';
import { themeColors } from '../styles';

export const HighlightTextStyle = styled.div<{
  isHighlight?: number;
  activate?: boolean;
  hideLight?: boolean;
}>`
  opacity: ${(props) => (props.activate ? '1' : '0')};
  color: ${(props) => (props.activate ? '#fff' : '#222')};
  transition: all 1s;
  text-shadow: 0px 0px 10px rgba(255, 255, 255, 1);
`;

export const VideoButton = styled.div<{
  width?: number;
}>`
  width: ${(props) => (props.width ? `${props.width}px` : '30px')};
  min-width: ${(props) => (props.width ? `${props.width}px` : '30px')};
  max-width: ${(props) => (props.width ? `${props.width}px` : '30px')};
  height: ${(props) => (props.width ? `${props.width}px` : '30px')};
  min-height: ${(props) => (props.width ? `${props.width}px` : '30px')};
  max-height: ${(props) => (props.width ? `${props.width}px` : '30px')};
  background: ${themeColors.brand.primary};
  opacity: 0.9;
  border-radius: 50%;
  border: none;
  margin: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${themeColors.white.primary};
`;

export const HotspotStyleWrapper = styled.div<{
  color?: string;
  clickable?: boolean;
  theme?: any;
}>`
  color: rgba(0, 0, 0, 1);
  opacity: ${(props) => (props.clickable ? 1 : 0.4)};
  text-align: center;
  border-radius: 50%;
  padding: 1px;
  animation-name: ${(props) => (props.clickable ? `example` : ``)};
  animation-duration: 4s;
  animation-iteration-count: infinite;
  border: ${(props) =>
    props.clickable
      ? `2px solid ${props.theme.brand.primary};`
      : `1px solid ${props.theme.white.primary}`};

  cursor: ${(props) => (props.clickable ? `pointer` : `default`)};

  transition: all 0.2s;
  &:hover {
    background: ${(props) =>
      props.clickable ? 'rgba(255, 255, 255, 0.2)' : ''};
  }
  @keyframes example {
    0% {
      box-shadow: ${(props) => `inset 0px 0px 5px ${props.theme.brand.primary}`},
        ${(props) => `0px 0px 5px ${props.theme.brand.primary}`};
    }
    75% {
      box-shadow: ${(props) =>
          `inset 0px 0px 25px ${props.theme.brand.primary}`},
        ${(props) => `0px 0px 25px ${props.theme.brand.primary}`};
    }
    100% {
      box-shadow: ${(props) => `inset 0px 0px 5px ${props.theme.brand.primary}`},
        ${(props) => `0px 0px 5px ${props.theme.brand.primary}`};
    }
  }
`;

export const MenuButtonStyle = styled.button<{
  isActive?: boolean;
  theme?: any;
}>`
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 20px;
  white-space: nowrap;
  margin: 0;
  height: 36px;
  cursor: pointer;
  font-family: museo-sans, sans-serif;
  border: ${(props) => `1px solid ${props.theme.grey.navBar}`};
  background: ${(props) =>
    props.isActive ? props.theme.gradient.primary : props.theme.grey.navBar};
  color: ${(props) =>
    props.isActive
      ? props.theme.brand.menuNoHoverText
      : props.theme.white.primary};
  &:hover {
    background: ${(props) => `${props.theme.gradient.primary}`};
    color: ${(props) => `${props.theme.brand.menuHoverText}`};
  }
`;

export const ChildMenuButtonStyle = styled.button<{
  isActive?: boolean;
  isOpen?: boolean;
  theme?: any;
}>`
  background: ${(props) =>
    props.isActive ? props.theme.gradient.primary : props.theme.grey.dark};
  border: ${(props) => (props.isOpen ? `1px solid rgba(0,0,0,0.2)` : 'none')};
  border-top: none;
  padding: ${(props) =>
    props.isOpen ? '8px 20px 8px 20px' : '0px 21px 0 21px'};
  box-sizing: border-box;
  overflow: hidden;
  width: '100%';
  color: ${(props) =>
    props.isActive ? props.theme.grey.black : props.theme.white.primary};
  cursor: pointer;
  max-height: ${(props) => (props.isOpen ? '100px' : '0px')};
  line-height: 1.32;
  margin: 0;
  &:hover {
    background: ${(props) => `${props.theme.gradient.primary}`};
    color: ${(props) => `${props.theme.brand.menuHoverText}`};
  }
`;

export const StartButtonStyle = styled.div`
  padding: 0px;
  color: ${themeColors.white.primary};
  border: none;
  overflow: hidden;
  font-weight: normal;
  transition: all 1s;
  background: rgba(0, 0, 0, 0);
  opacity: 0.8;
  font-size: 1.5rem;
  border-radius: 10px;
  color: ${themeColors.white.primary};
`;

export const IconStyle = styled.div<{ isMobile?: boolean }>`
  max-width: 90px;
  max-height: 90px;
  height: 10vw;
  width: 10vw;
  min-height: 60px;
  min-width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border:2px solid ${themeColors.white.primary};
  transition:all 0.2s;
  margin: 20px 30px 0 30px;
  cursor: pointer;
  animation-name: example3;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  position: relative;

  @keyframes example3 {
    0% {
      box-shadow: 0px 0px 5px #fff;
    }
    55% {
      box-shadow: 0px 0px 10px #fff;
  }
  100% {
    box-shadow: 0px 0px 5px #fff;
  }
`;

export const HotspotStyle = styled.div<{
  hotspot?: number[];
}>`
  transition: opacity 1s;
  position: fixed;
  height: 6vh;
  width: 6vh;
  background-color: ${themeColors.brand.primary};
  border-radius: 50%;
  border: none;
  margin-left: ${(props) => (props.hotspot ? `${props.hotspot[0]}vh` : 0)};
  margin-top: ${(props) => (props.hotspot ? `${props.hotspot[1]}vh` : 0)};
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5);
  opacity: 1;

  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const RectangleButtonWrapperStyle = styled.div<{
  offset?: number[];
  clickable: boolean;
  theme: any;
}>`
  transition: opacity 1s;

  background: ${(props) =>
    props.clickable
      ? props.theme.gradient.secondary
      : props.theme.gradient.primary};
  border: 1px solid #444;
  min-width: 150px;
  padding: 0px;
  position: absolute;
  margin-left: ${(props) => (props.offset ? `${props.offset[1]}vh` : 0)};
  margin-top: ${(props) => (props.offset ? `${props.offset[0]}vh` : 0)};
  opacity: 1;
  z-index: 3;
  display: flex;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  color: ${(props) => (props.clickable ? '#fff' : '#222')};
`;

export const RectangleButtonSquareStyle = styled.div`
  height: 100%;
  padding: 10px;
  width: 20px;
  background: ${themeColors.brand.primary};
  border-left: 1px solid #444;
`;

export const RectangleButtonStyle = styled.div<{
  offset?: number[];
  clickable: boolean;
}>`
  transition: opacity 1s;
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  padding: 10px;

  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  color: ${(props) => (props.clickable ? '#fff' : '#222')};
`;

export const BackButtonStyle = styled.div<{
  bg?: string;
}>`
  transition: opacity 1s;
  position: fixed;
  display: flex;
  z-index: 1;
  flex-direction: column;
  align-items: center;
  left: 0px;
  top: 20px;
  cursor: pointer;
  opacity: 1;

  &:hover {
    opacity: 0.7;
  }
`;
