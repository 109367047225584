import React from 'react';
import { themeColor } from '../cmsConfig';
export default function CheckButton(props: {
  onClickEvent: () => void;
  title: string;
  selected: boolean;
  noCheck?: boolean;
}) {
  const { onClickEvent, title, selected, noCheck } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginRight: 20,
        cursor: 'pointer',
        minWidth: 100,
        borderRadius: 5,
        padding: 5,
        margin: 3,
      }}
      onClick={() => onClickEvent()}
    >
      {!noCheck && (
        <div
          style={{
            border: 'none',
            background: selected ? themeColor : '#aaa',
            borderRadius: '50%',
            minWidth: 12,
            minHeight: 12,
            maxWidth: 12,
            maxHeight: 12,
          }}
        ></div>
      )}
      <button
        style={{
          border: 'none',
          background: 'none',
          cursor: 'pointer',
        }}
      >
        {title}
      </button>
    </div>
  );
}
