import styled from 'styled-components';

export const CTA = styled.button<{
  theme: any;
}>`
  color: ${(props) => props.theme.white.primary};
  border: none;
  background: none;
  font-size: 1.1rem;
  opacity: 1;
  animation-name: example2;
  max-width: 200px;
  font-family: museo-sans, sans-serif;
  margin-left: -200px;
  margin-right: 200px;
  pointer-events: none;
  user-select: none;
  position: relative;
`;

export const RectangleButtonWrapperStyle = styled.div<{
  clickable: boolean;
  isOpen: boolean;
  theme: any;
  firstOrange?: boolean;
  grey?: boolean;
  white?: boolean;
  offset?: number[];
}>`
  transition: opacity 1s;
  background: ${(props) =>
    props.white
      ? '#fff'
      : props.grey
      ? props.theme.grey.dark
      : props.theme.gradient.primary};
  min-height: 54px;
  margin-top: ${(props) => (props.firstOrange ? '10px' : '0px')};
  border: 1px solid #444;
  border-bottom: ${(props) => (props.isOpen ? 'none' : ' 1px solid #444;')};
  min-width: 150px;
  padding: 0px;
  width: 100%;
  max-width: 250px;
  min-width: 250px;
  margin-left: -54px;
  margin-right: 42px;
  opacity: 1;
  z-index: 3;
  display: flex;
  position: relative;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
`;

export const RectangleButtonWrapperInnerStyle = styled.button<{}>`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0;
  background: none;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  border: none;
  min-height: inherit;
`;

export const ButtonIcon = styled.img<{
  white?: boolean;
  theme: any;
  isOn?: boolean;
}>`
  filter: ${(props) => (props.isOn ? 'invert(0%)' : 'invert(100%)')};
`;

export const RectangleButtonSquareStyle = styled.div<{
  white?: boolean;
  theme: any;
  isOn?: boolean;
}>`
  padding: 0px 10px 0px 10px;
  background: ${(props) =>
    props.white
      ? !props.isOn
        ? '#fff'
        : props.theme.grey.primary
      : props.theme.brand.primary};
  display: flex;
  align-items: center;
  height: 100%;
  min-height: 100%;
  border-right: 1px solid #444;
  min-height: inherit;
`;

export const RectangleButtonSquareInnerStyle = styled.div<{
  isOn?: boolean;
  theme: any;
}>`
  transition: transform 1s;
  transform: ${(props) => (props.isOn ? 'rotate(45deg)' : 'rotate(0deg)')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
`;
export const RectangleButtonStyle = styled.div<{
  offset?: number[];
  clickable: boolean;
  white?: boolean;
  grey?: boolean;
  isOpen: boolean;
  theme: any;
}>`
  transition: opacity 1s;
  height: 100%;
  width: 100%;
  min-height: inherit;
  background: ${(props) =>
    props.isOpen ? props.theme.gradient.primary : '0px'};
  border: none;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: pointer;
  justify-content: center;
  font-weight: ${(props) => (props.white || props.grey ? 500 : 900)};
  font-family: museo-sans, sans-serif;
  color: ${(props) =>
    props.grey
      ? props.theme.white.primary
      : props.clickable
      ? props.theme.grey.black
      : '#222'};
`;

export const ContentPanelStyle = styled.div<{
  isOpen: boolean;
  fullscreen: boolean;
  isMobile: boolean;
}>`
  box-sizing: border-box;
  transition: ${(props) =>
    props.fullscreen || props.isMobile ? '' : ' max-height 0.5s'};
  overflow: hidden;
  width: ${(props) => (props.fullscreen ? '100vw' : '350px')};
  background: ${(props) => (props.fullscreen ? 'rgba(0,0,0,0.8)' : '')};
  height: ${(props) => (props.fullscreen ? '100vh' : '40vh')};
  margin: ${(props) => (props.fullscreen ? '0px' : '0 0 0 -194px')};
  padding: ${(props) =>
    props.isMobile ? '0px' : props.fullscreen ? '80px 0 10px 0' : '0'};
  z-index: ${(props) => (props.fullscreen ? 100000 : '0')};
  max-height: ${(props) => (props.isOpen ? '100vh' : '0vh')};
  position: ${(props) => (props.fullscreen ? 'fixed' : 'relative')};
  top: ${(props) => (props.fullscreen ? '0' : '0')};
  left: ${(props) => (props.fullscreen ? 0 : '')};
`;

export const ContentPanelInnerStyle = styled.div<{
  isOpen: boolean;
  theme: any;
  fullscreen: boolean;
  isMobile: boolean;
}>`
  background: ${(props) => props.theme.brand.primary};
  width: 100%;
  max-width: ${(props) => (props.isMobile ? '100vw' : 600)};
  margin: auto;
  box-sizing: border-box;
  transition: ${(props) =>
    props.fullscreen || props.isMobile ? '' : ' max-height 0.5s'};
  max-height: ${(props) => (props.isOpen ? '100%' : '0vh')};
  height: 100%;
  z-index: 2;
  pointer-events: auto;

  padding: ${(props) =>
    props.isMobile && props.isOpen
      ? '100px'
      : props.fullscreen
      ? '10vw'
      : '10px'};
  border: 1px solid #222;
  overflow: auto;
  position: relative;
  font-size: ${(props) => (props.isMobile ? '2vw' : '1rem')};
  cursor: pointer;
  sup {
    font-size: 0.6em;
  }
  ol {
    border-top: 1px solid rgba(255, 255, 255, 0.2);
    padding-top: 20px;
    font-size: 0.6em;
  }
`;
