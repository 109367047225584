import styled from 'styled-components';

export const RoundButtonWrapperStyle = styled.div<{
  offset?: number[];
  isActive: boolean;
  theme: any;
}>`
  transition: opacity 1s;
  background: ${(props) =>
    props.isActive ? props.theme.gradient.primary : 'none'};
  border: 1px solid #222;
  min-width: 150px;
  padding: 0px;
  width: 50px;
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  min-height: 50px;
  max-height: 50px;
  border-radius: 50%;
  left: 0;
  opacity: 1;
  z-index: 3;
  display: flex;
  margin: 5px;
  cursor: pointer;
`;

export const RoundButtonStyle = styled.button<{
  offset?: number[];
}>`
  transition: opacity 1s;
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  padding: 10px;

  cursor: pointer;
`;
