import React from 'react';
import { useGLTF } from '@react-three/drei';
import { HotspotType3D } from '../types';
import { Html } from '@react-three/drei';
import { HotspotStyleWrapper } from './styles';
import touchOne from '../assets/icons/touchOne.svg';
import { ThemeContext } from '../context';
export default function Model(props: {
  model: string;
  hotspots: HotspotType3D[];
  hotspotEvent: (targetRotation: number, onClickEvent: () => void) => void;
  rotation?: number;
  cmsData?: any;
  editButton?: any;
}) {
  const { hotspots, hotspotEvent, rotation, cmsData } = props;
  //@ts-ignore'
  const { nodes } = useGLTF(props.model);
  const nodeArr = Object.keys(nodes);

  return (
    <group dispose={null} position={[0, 0, 0]} scale={1} rotation={[0, 0, 0]}>
      <group scale={1} position={[0, 0, 0]}>
        {nodeArr.map((node, index) => {
          //@ts-ignore
          if (!nodes[node].geometry) return null;

          return (
            //@ts-ignore
            <mesh geometry={nodes[node].geometry} key={`group_${index}`}>
              <meshStandardMaterial opacity={0} transparent color="#d8e0e6" />
            </mesh>
          );
        })}
      </group>

      {hotspots &&
        hotspots.map((hotspot, index) => (
          <group key={`group_${index}`} position={hotspot.pos}>
            <Html center={true}>
              <div
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  transition: 'all 0.3s',
                  opacity: rotation
                    ? !hotspot.visible
                      ? hotspot.opacity
                      : (rotation > hotspot.visible[0] &&
                          rotation < hotspot.visible[1]) ||
                        (rotation > hotspot.visible[2] &&
                          rotation < hotspot.visible[3])
                      ? hotspot.opacity
                      : 0
                    : hotspot.opacity,
                }}
              >
                <ThemeContext.Consumer>
                  {({ theme }) => (
                    <HotspotStyleWrapper
                      clickable={hotspot.clickEvent ? true : false}
                      theme={theme}
                    >
                      <div
                        style={{
                          textAlign: 'center',
                          padding: 5,
                          borderRadius: '50%',
                          width: '5vh',
                          height: '5vh',
                          minWidth: '5vh',
                          minHeight: '5vh',
                          boxShadow: ' 0px 0px 5px rgba(0,0,0,0.1)',
                          fontFamily: 'Roboto,sans-serif',
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          color: '#fff',
                          position: 'relative',
                        }}
                        onClick={() =>
                          hotspotEvent &&
                          hotspotEvent(
                            hotspot.targetRotation === undefined
                              ? -1
                              : hotspot.targetRotation,
                            hotspot.clickEvent ? hotspot.clickEvent : () => null
                          )
                        }
                      >
                        {hotspot.cmsId && (
                          <>
                            <div
                              style={{
                                color: 'rgba(0,0,0,1)',
                                boxShadow: ' 0px 0px 5px rgba(0,0,0,0.1)',
                                fontFamily: 'Roboto,sans-serif',
                                borderBottom: '1px solid #fff',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                minWidth: '230px',
                                right: '100%',
                                position: 'absolute',
                              }}
                            >
                              <div
                                style={{
                                  color: 'rgba(255,255,255,1)',
                                  textAlign: 'left',
                                  padding: 5,
                                  borderRadius: 2,
                                  width: 132,
                                  fontFamily: 'Roboto,sans-serif',
                                  position: 'absolute',
                                  left: '-4px',
                                  bottom: '-30px',
                                }}
                              >
                                {cmsData &&
                                  cmsData.labels &&
                                  cmsData.labels[hotspot.cmsId] &&
                                  cmsData.labels[hotspot.cmsId].label}
                              </div>
                            </div>
                          </>
                        )}
                        {hotspot.clickEvent && (
                          <img
                            src={touchOne}
                            style={{ width: 32 }}
                            alt="touch"
                          />
                        )}
                      </div>
                    </HotspotStyleWrapper>
                  )}
                </ThemeContext.Consumer>
              </div>
            </Html>
          </group>
        ))}
    </group>
  );
}
