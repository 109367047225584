import React from 'react';

export function TextArea(props: {
  content: any;
  onChangeEvent: (e: any) => void;
  placeholder?: any;
}) {
  const { content, onChangeEvent, placeholder } = props;
  return (
    <textarea
      style={{
        background: 'rgba(20,20,20,0.95)',
        borderRadius: '10px',
        width: '100%',
        padding: '1rem',
        border: '2px solid #fff',
        boxShadow: '2px 2px 10px rgba(0,0,0,0.3)',
        maxWidth: '100%',
        minWidth: '100%',
        color: '#fff',
      }}
      value={content}
      placeholder={placeholder}
      onChange={(e) => onChangeEvent(e)}
    />
  );
}
