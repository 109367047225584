export const contentTypes = ['testing', 'html-text', 'mix-content'];
export const subContentTypes = ['img', 'html-text'];

export const defaultValues = {
  label: '',
  content: '',
  textContent: { html_content: '' },
  media: '',
  title: '',
  page: 0,
  pages: 1,
  hoverLabel: '',
};

export const defaultHotspot = {
  index: 0,
  page: 0,
  type: 'hotspots',
  file: '',
};
