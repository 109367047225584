import styled from 'styled-components';
import { themeColors } from '../../styles';

export const RectangleButtonWrapperStyle = styled.div<{
  offset?: number[];
  clickable: boolean;
}>`
  transition: opacity 1s;
  background: ${(props) =>
    props.clickable ? themeColors.grey.primary : 'gradient'};
  border: 1px solid #444;
  min-width: 150px;
  min-height: 54px;
  padding: 0px;
  width: 100%;
  left: 0;
  opacity: 1;
  z-index: 3;
  display: flex;
  position: relative;
  cursor: pointer;
  color: ${(props) => (props.clickable ? '#fff' : '#222')};
`;
export const RectangleButtonWrapperInnerStyle = styled.button<{}>`
  display: flex;
  position: relative;
  padding: 0;
  background: none;
  border: none;
  width: 100%;
`;

export const RectangleButtonSquareStyle = styled.div<{
  theme?: any;
}>`
  padding: 0 15px 0 15px;
  height: 100%;
  width: 20px;
  background: ${(props) => props.theme.brand.primary};
  border-left: 1px solid #444;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const RectangleButtonStyle = styled.div<{
  offset?: number[];
  clickable: boolean;
}>`
  transition: opacity 1s;
  height: 100%;
  width: 100%;
  background: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  box-sizing: border-box;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  color: ${(props) => (props.clickable ? '#fff' : '#222')};
`;
