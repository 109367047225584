import React from 'react';
import { RightMenu, RightMenuButton } from './components';
import { Page } from '../../types';
import { ContentContext } from '../../context';
import { getHotspotPages } from '../../utils/getHotspotPages';

export function RightMenuElocta(props: {
  setPage: React.Dispatch<React.SetStateAction<any>>;
  page: Page;
}) {
  const { page, setPage } = props;

  const [openItem, setOpenItem] = React.useState(-1);
  return (
    <ContentContext.Consumer>
      {({ cmsData, editButton }) => (
        <RightMenu>
          {getHotspotPages(cmsData.hotspots, page).map((item, index) => {
            return (
              <RightMenuButton
                isOpen={openItem === index}
                setOpenItem={setOpenItem}
                index={index}
                item={item}
                editButton={editButton(item.file, 'hotspots')}
                setPage={setPage}
                key={`index-menu-${index}`}
              />
            );
          })}
        </RightMenu>
      )}
    </ContentContext.Consumer>
  );
}
