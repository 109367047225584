import React, { useMemo } from 'react';
import { Frames } from '../../components';
import { Page } from '../../types';
import {
  arthropathy,
  arthropathyAnim,
  arthropathyPost,
  arthropathyExternal,
} from '../../data/ankle/arthropathy';

import { RightMenuOne } from '../../menu/RightMenuOne';
import { LeftMenuOne } from '../../menu/LeftMenuOne';
import { PageType } from '../../types';
import { getTrackImages } from '../../utils/getTrackImages';
import { Frames360New } from '../../components/Frames360New';

import { shouldRender, shouldFadeOut } from '../../utils/fadeAndVisible';
import { Fade } from '../../components';

export function Arthropathy(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
  cmsData: any;
}) {
  const { changePage, pageArray, cmsData } = props;

  const memoProps: any = useMemo(() => {
    return [
      [
        pageArray[0].page === Page.Arthropathy,
        pageArray[0].page === Page.Arthropathy
          ? pageArray[0].animateOut
          : false,
      ],
      [
        pageArray[0].page === Page.ArthropathyAnimation,
        pageArray[0].animateOut &&
          pageArray[0].page === Page.ArthropathyAnimation,
      ],
      [
        pageArray[0].page === Page.ArthropathyPost,
        pageArray[0].animateOut && pageArray[0].page === Page.ArthropathyPost,
      ],
      [pageArray[0].page === Page.Arthropathy360],
      [
        pageArray[0].page === Page.ArthropathyExternal,
        pageArray[0].animateOut &&
          pageArray[0].page === Page.ArthropathyExternal,
      ],
      [pageArray[0].page === Page.ArthropathyExternal360],
    ];
  }, [pageArray]);

  return (
    <>
      {useMemo(() => {
        const thisPage = Page.Arthropathy;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              background="#444"
              images={getTrackImages(() => arthropathy(), changePage)}
              animateOut={memoProps[0][1]}
              storyboard={arthropathy()}
              rightMenu={
                <RightMenuOne setPage={changePage} page={Page.Arthropathy} />
              }
              leftMenu={
                <LeftMenuOne setPage={changePage} page={Page.Arthropathy} />
              }
              id="arthropathy"
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.ArthropathyAnimation;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => arthropathyAnim(53, cmsData),
                changePage
              )}
              animateOut={memoProps[1][1]}
              storyboard={arthropathyAnim(53, cmsData)}
              isVideo={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.ArthropathyAnimation}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.ArthropathyAnimation}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.Arthropathy,
                  visible: true,
                  transitionFrame: -1,
                  animateOut: false,
                })
              }
              id="arthropathy-animation"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray, cmsData])}

      {useMemo(() => {
        const thisPage = Page.ArthropathyPost;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(() => arthropathyPost(), changePage)}
              background="#444444"
              animateOut={memoProps[2][1]}
              storyboard={arthropathyPost()}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.ArthropathyPost}
                />
              }
              leftMenu={
                <LeftMenuOne setPage={changePage} page={Page.ArthropathyPost} />
              }
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    transitionFrame: 0,
                    visible: true,
                    animateOut: true,
                  }),
                targetRotation: 0,
              }}
              id="arthropathy-post"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.ArthropathyExternal;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(() => arthropathyExternal(), changePage)}
              animateOut={memoProps[4][1]}
              storyboard={arthropathyExternal()}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.ArthropathyExternal}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.ArthropathyExternal}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.ArthropathyPost,
                  visible: true,
                  transitionFrame: -1,
                  animateOut: false,
                })
              }
              id="arthropathy-external"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.Arthropathy360;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[3][0]}
              config={{
                file: 'Orbit_Ankle_Arthropothy_Zoom_',
                zooms: 2,
                levels: 1,
                frames: 59,
                dir: 'ankle/arthropathy',
              }}
              rightMenu={
                <RightMenuOne setPage={changePage} page={Page.Arthropathy360} />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.ArthropathyPost,
                  visible: true,
                  transitionFrame: -1,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.ArthropathyExternal360;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames360New
              visible={memoProps[5][0]}
              config={{
                file: 'Orbit_Ankle_Arthropothy_Zoom_',
                zooms: 2,
                levels: 2,
                frames: 59,
                dir: 'ankle/arthropathyExternal',
              }}
              external={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.ArthropathyExternal360}
                />
              }
              closeMenuEvent={() =>
                changePage({
                  page: Page.ArthropathyExternal,
                  visible: true,
                  transitionFrame: -1,
                })
              }
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}
    </>
  );
}
