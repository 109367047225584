import { Vector3, Euler } from 'three';
import { StoryboardType } from '../../../types';

export const storyboard = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  for (let i = 0; i < maxRotation; i++) {
    if (i < 2) {
      storyboardArr.push({
        _id: i,
        img: `${appAssets}/zoomtoknee/${i}.jpg`,
        allowControls: false,
        loop: true,
        video: `${appAssets}/zoomToKnee.mp4`,
        bg: '#454545',
      });
    } else {
      storyboardArr.push({
        _id: i,
        img: `${appAssets}/zoomtoknee/${i}.jpg`,
        allowControls: false,
        loop: true,
        bg: '#454545',
        pos: new Vector3(-6, 27, 0),
        rot: new Euler(0, 0, 0),
        scale: 50,
        hotspots: [
          {
            cmsId: 'femur',
            opacity: 1,
            pos: [0.12, -0.43, 0.05],
            label: 'Femur',
          },
          {
            cmsId: 'patella',
            opacity: 1,
            pos: [0.12, -0.5, 0.05],
            label: 'Patella',
          },
          {
            cmsId: 'tibia',
            opacity: 1,
            pos: [0.12, -0.57, 0.05],
            label: 'Tibia',
          },
        ],
        video: `${appAssets}/zoomToKnee.mp4`,
      });
    }
  }

  return storyboardArr;
};
