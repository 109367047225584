import React, { useMemo } from 'react';
import { Frames } from '../components';
import { Page } from '../types';

import { RightMenuOne } from '../menu/RightMenuOne';
import { PageType } from '../types';
import { getTrackImages } from '../utils/getTrackImages';
import { storyboardMan } from '../data/storyboardMan';
import { shouldRender, shouldFadeOut } from '../utils/fadeAndVisible';
import { Fade } from '../components';

export function Landing(props: {
  thisPage: Page;
  pageArray: PageType[];
  rightMenu?: JSX.Element;
  changePage: (newPage: PageType) => void;
  setPageSet: (pageSet: string) => void;
}) {
  const { changePage, thisPage, pageArray, setPageSet } = props;
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  const memoProps: any = useMemo(() => {
    return [[thisPage === pageArray[0].page, true]];
  }, [thisPage, pageArray]);

  return (
    <>
      {useMemo(() => {
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardMan(60, null, setPageSet),
                changePage
              )}
              model={`${appAssets}/man/scene.gltf`}
              animateOut={memoProps[0][1]}
              storyboard={storyboardMan(60, null, setPageSet)}
              rightMenu={
                <RightMenuOne setPage={changePage} page={Page.Landing} />
              }
              id="3d-man"
              autoAnimate={false}
              circle={true}
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray, thisPage, setPageSet, appAssets])}
    </>
  );
}
