import styled from 'styled-components';

export const LeftMenuStyle = styled.div<{
  bg?: string;
}>`
  padding: 10px;
  transition: opacity 1s;
  position: fixed;
  display: flex;
  z-index: 3;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  left: 0px;
  height: 100vh;
  cursor: pointer;
  opacity: 1;
`;
