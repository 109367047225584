import React, { useRef } from 'react';
import { Row, Column } from './';
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { themeColors } from '../styles';
import { VideoButton } from './styles';
import playArrowIcon from '../assets/icons/play.svg';
import pauseIcon from '../assets/icons/pause.svg';
import rewindIcon from '../assets/icons/rewind.svg';
import forwardFullIcon from '../assets/icons/forwardFull.svg';
import forwardIcon from '../assets/icons/forward.svg';
import rewindTwoIcon from '../assets/icons/rewindTwo.svg';
import { throttle } from '../utils/throttle';

export const Timeline = (props: {
  rotation: number;
  setRotation: (rotation: number) => void;
  setScroll: any;
  maxRotation: number;
  playAnimation: (playing: boolean) => void;
  animationPlaying: boolean;
  storyboard: any;
}) => {
  const {
    setRotation,
    rotation,
    maxRotation,
    playAnimation,
    animationPlaying,
    storyboard,
    setScroll,
  } = props;

  const canRef = useRef<any>(null);

  return (
    <Column
      style={{
        zIndex: 100,
        position: 'fixed',
        bottom: 80,
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Row
        style={{
          zIndex: 100,
          position: 'fixed',
          bottom: 140,
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <VideoButton onClick={() => setScroll(0.01)}>
          <img src={rewindTwoIcon} style={{ width: '100%' }} alt="rewind" />
        </VideoButton>
        <VideoButton
          width={40}
          onClick={() =>
            setScroll(rotation > 0 ? Number((rotation - 0.025).toFixed(2)) : 0)
          }
        >
          <img src={rewindIcon} style={{ width: '100%' }} alt="step back" />
        </VideoButton>
        <VideoButton
          width={50}
          onClick={() => {
            if (rotation === maxRotation) {
              setRotation(0);
            }
            playAnimation(animationPlaying ? false : true);
          }}
        >
          {animationPlaying ? (
            <img src={pauseIcon} alt="pause" />
          ) : (
            <img src={playArrowIcon} alt="play" />
          )}
        </VideoButton>
        <VideoButton
          width={40}
          onClick={() =>
            setScroll(
              rotation < maxRotation
                ? Number((rotation * 1 + 0.025).toFixed(2))
                : maxRotation
            )
          }
        >
          <img src={forwardIcon} style={{ width: '100%' }} alt="step forward" />
        </VideoButton>
        <VideoButton onClick={() => setScroll(maxRotation)}>
          <img src={forwardFullIcon} style={{ width: '100%' }} alt="forward" />
        </VideoButton>
      </Row>
      <Slider
        min={0}
        max={maxRotation}
        value={rotation}
        step={0.01}
        onChange={(value) =>
          throttle(
            () =>
              props.setScroll(
                Array.isArray(value) ? value[0] : Number(value.toFixed(2))
              ),
            canRef
          )
        }
        style={{ margin: 10, width: '30vw', boxSizing: 'border-box' }}
        trackStyle={{
          backgroundColor: themeColors.white.primary,
          height: '1px',
        }}
        railStyle={{
          backgroundColor: themeColors.grey.primary,
          height: '1px',
        }}
        handleStyle={{
          background: themeColors.brand.primary,
          border: `none`,
          boxShadow: `0 0 5px rgba(0,0,0,0.3)`,
          borderRadius: 0,
          width: 30,
          opacity: 1,
        }}
      />
      {storyboard[Math.floor(rotation)] &&
        storyboard[Math.floor(rotation)].timePeriod && (
          <div
            style={{
              zIndex: 90,
              top: 0,
              position: 'fixed',
              right: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            <div
              style={{
                height: '60%',
                background: themeColors.grey.primary,
                width: 1,
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <div
                style={{
                  padding: 4,
                  position: 'absolute',
                  top: `${(rotation / maxRotation) * 100}%`,
                  background: themeColors.grey.primary,
                  color: themeColors.white.primary,
                  fontSize: '0.6rem',
                  zIndex: 1,
                }}
              >
                {storyboard[Math.ceil(rotation)] &&
                  storyboard[Math.ceil(rotation)].timePeriod.title}
              </div>
              {storyboard.map((item: any, index: number) => {
                const period = item.timePeriod.step;
                const isDivisible = index % period === 0;
                const isTen = index % item.timePeriod.divisible === 0;
                if (!isDivisible) {
                  return null;
                }
                return (
                  <div
                    key={`timeline-${index}`}
                    style={{
                      position: 'absolute',
                      top: `${(index / maxRotation) * 100}%`,
                      borderBottom: `1px solid ${themeColors.grey.primary}`,
                      width: isTen ? 10 : 6,
                    }}
                  ></div>
                );
              })}
            </div>
          </div>
        )}
    </Column>
  );
};
