import React from 'react';

export function CMButton(props: {
  showControls: boolean;
  setShowControls: (showControls: boolean) => void;
}) {
  const { showControls, setShowControls } = props;
  return (
    <button
      onClick={() => setShowControls(showControls ? false : true)}
      style={{
        border: '2px solid white',
        minWidth: 34,
        minHeight: 34,
        maxWidth: 34,
        maxHeight: 34,
        margin: 10,
        marginRight: 0,
        borderRadius: showControls ? '50% 0 0 50%' : '50%',
        cursor: 'pointer',
        fontSize: 12,
      }}
    >
      CM
    </button>
  );
}
