import { PageType, Page } from '../types';

export function shouldRender(pageArray: PageType[], thisPage: Page): boolean {
  const renderLogic =
    pageArray[0].page === thisPage ||
    (pageArray[1] && pageArray[1].page === thisPage)
      ? true
      : false;
  return renderLogic;
}

export function shouldFadeOut(pageArray: PageType[], thisPage: Page): boolean {
  const fadeLogic =
    pageArray[1] &&
    pageArray[1].page === thisPage &&
    pageArray[0].page !== thisPage
      ? true
      : false;
  return fadeLogic;
}
