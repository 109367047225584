import React, { useMemo } from 'react';
import { Frames } from '../../components';
import { Page } from '../../types';
import {
  storyboardSubclinicalBleed,
  storyboardSubclinicalBleedAnimation,
  storyboardSubclinicalBleedPostAnimation,
} from '../../data/ankle/subclinicalBleed';
import { PageType } from '../../types';
import { getTrackImages } from '../../utils/getTrackImages';
import { RightMenuOne } from '../../menu/RightMenuOne';
import { LeftMenuOne } from '../../menu/LeftMenuOne';
import { shouldRender, shouldFadeOut } from '../../utils/fadeAndVisible';
import { Fade } from '../../components';

export function SubclinicalBleed(props: {
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
  cmsData: any;
}) {
  const { changePage, pageArray, cmsData } = props;

  const memoProps: any = useMemo(() => {
    return [
      [
        pageArray[0].page === Page.SubclinicalBleed,
        pageArray[0].page === Page.SubclinicalBleed
          ? pageArray[0].animateOut
          : false,
      ],
      [
        pageArray[0].page === Page.SubclinicalBleedAnimation,
        pageArray[0].page === Page.SubclinicalBleedAnimation
          ? pageArray[0].animateOut
          : false,
      ],
      [pageArray[0].page === Page.AfterBleedExternalSubclinical],
    ];
  }, [pageArray]);

  return (
    <>
      {useMemo(() => {
        const thisPage = Page.SubclinicalBleed;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardSubclinicalBleed(1, null),
                changePage
              )}
              animateOut={memoProps[0][1]}
              storyboard={storyboardSubclinicalBleed(1, null)}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.SubclinicalBleed}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.SubclinicalBleed}
                />
              }
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    visible: true,
                    transitionFrame: -1,
                    animateOut: false,
                  }),
                targetRotation: 0,
              }}
              id="subclinical-bleed"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray])}

      {useMemo(() => {
        const thisPage = Page.SubclinicalBleedAnimation;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardSubclinicalBleedAnimation(52, null, cmsData),
                changePage
              )}
              animateOut={memoProps[1][1]}
              storyboard={storyboardSubclinicalBleedAnimation(
                52,
                null,
                cmsData
              )}
              isVideo={true}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.SubclinicalBleedAnimation}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.SubclinicalBleedAnimation}
                />
              }
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    visible: true,
                    transitionFrame: -1,
                    animateOut: false,
                  }),
                targetRotation: 0,
              }}
              id="subclinical-bleed-animation"
            />
          </Fade>
        );
      }, [memoProps, changePage, pageArray, cmsData])}

      {useMemo(() => {
        const thisPage = Page.AfterBleedExternalSubclinical;
        return (
          <Fade
            visible={!shouldFadeOut(pageArray, thisPage)}
            shouldRender={shouldRender(pageArray, thisPage)}
          >
            <Frames
              images={getTrackImages(
                () => storyboardSubclinicalBleedPostAnimation(),
                changePage
              )}
              storyboard={storyboardSubclinicalBleedPostAnimation()}
              isVideo={false}
              rightMenu={
                <RightMenuOne
                  setPage={changePage}
                  page={Page.AfterBleedExternalSubclinical}
                />
              }
              leftMenu={
                <LeftMenuOne
                  setPage={changePage}
                  page={Page.AfterBleedExternalSubclinical}
                />
              }
              backMenuEvent={{
                event: () =>
                  changePage({
                    page: Page.Landing,
                    visible: true,
                    transitionFrame: -1,
                    animateOut: false,
                  }),
                targetRotation: 0,
              }}
              id="post-subclinical-bleed"
            />
          </Fade>
        );
      }, [changePage, pageArray])}
    </>
  );
}
