import { StoryboardType } from '../../../types';

export const storyboardSubclinicalBleed = (
  maxRotation: number,
  quality: string | null
) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: 1,
    img: `${appAssets}/ankle/healthyInternal/zoom0Level1/Ankle_HealthyInternal_Zoom1_0000.jpg`,
    allowControls: false,
  });
  return storyboardArr;
};
