import React from 'react';

export function LabelTitle(props: {
  content: any;
  setContent: (content: any) => void;
}) {
  const { content, setContent } = props;
  return (
    <input
      style={{
        background: 'none',
        width: '100%',
        marginLeft: 2,
        marginBottom: 3,
        fontWeight: 'bold',
        border: '2px solid rgba(0,0,0,0.1)',
        borderRadius: 5,
        boxSizing: 'border-box',
        padding: 10,
        minWidth: 400,
      }}
      value={content.label}
      placeholder="Label"
      onChange={(e) => setContent({ ...content, label: e.target.value })}
    />
  );
}
