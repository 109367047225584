import { StoryboardType } from '../../../types';

export const storyboardAfterTheBleed = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: 1,
    img: `${appAssets}/ankle/subclinical/zoom0Level1/Orbit_Ankle_Subclinical_Zoom1_0000.jpg`,
    subtitle: '',
  });
  return storyboardArr;
};
