export function backEvent(
  onClickEvent: () => void,
  isBeingAnimated: any,
  isBeingControlled: any
) {
  isBeingAnimated.current = true;
  isBeingControlled.current = false;
  onClickEvent();
  /*
  if (rotation === targetRotation) {
    onCompleteEvent.current = () => null;
    onClickEvent();
  } else {
    onCompleteEvent.current = () => onClickEvent();
    setRotationTarget(targetRotation);
  }
  */
}
