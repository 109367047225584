import styled from 'styled-components';

export const ContentPanel = styled.div<{ theme: any }>`
  font-size: '1rem';
  margin: 0;
  padding: 10px;
  color: ${(props) => props.theme.brand.primary};
  font-family: proxima-nova, sans-serif;
  font-style: normal;
  line-height: 1;
  sup {
    font-size: 0.6em;
  }
`;
