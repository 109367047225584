import { Vector3, Euler } from 'three';
import { StoryboardType } from '../../../../types';

export const zoom0Level1 = (maxRotation: number) => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  for (let i = 0; i < maxRotation; i++) {
    if (i < 61) {
      storyboardArr.push({
        _id: i,
        pos: new Vector3(0, 7 - i / 10, 0),
        rot: new Euler(0, i / 64, 0),
        scale: 1 + i / 40,
        img: `${appAssets}/clinicalBleed/zoom0Level1/${i}.jpeg`,
      });
    }
  }
  return storyboardArr;
};
