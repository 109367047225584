import styled from 'styled-components';

export const Wrapper = styled.div<{
  backgroundColor?: string;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.backgroundColor};
  z-index: 1;
`;

export const Canvas = styled.canvas`
  aspect-ratio: 16/9;
  height: 100vh;
  z-index: 1;
  left: 0;
`;
