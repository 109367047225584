import React, { useRef } from 'react';

import { TextArea } from './TextArea';
import { LabelTitle } from './LabelTitle';
import { Editor } from '@tinymce/tinymce-react';
import { SaveButton } from '.';

export function ImgInputs(props: {
  showPreview: any;
  setShowPreview: any;
  content: any;
  setContent: any;
  saveEvent: any;
}) {
  const { showPreview, content, setContent, saveEvent } = props;
  const editorRef = useRef<any>();
  const [saved, setSaved] = React.useState(false);

  return (
    <div>
      {/*
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
        }}
      >
      
        <Label str="Editor:" w={100} />
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
          <CheckButton
            title="RICH TEXT"
            onClickEvent={() => setShowPreview('wys')}
            selected={showPreview === 'wys'}
          />
          <CheckButton
            title="HTML"
            onClickEvent={() => setShowPreview('html')}
            selected={showPreview === 'html'}
          />
      </div>
      </div>
      <Divider />*/}
      <div
        style={{
          padding: 10,
          boxSizing: 'border-box',
        }}
      >
        <LabelTitle content={content} setContent={setContent} />
        {showPreview === 'wys' ? (
          <div>
            {content.media && (
              <TextArea
                content={content.media}
                onChangeEvent={(e) =>
                  setContent({ ...content, media: e.target.value })
                }
                placeholder="media"
              />
            )}
            {content.content && (
              <Editor
                apiKey="qvh76c7734yj3ptv8a3bx6qyt4fkethac5fk2amkdqmodb8l"
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={content.content}
                init={{
                  height: 500,
                  menubar: false,
                  content_style: 'body { background: #eaeaea;color:#000; }',
                  plugins: ['lists', 'code'],
                  toolbar: [
                    'code | bold italic | bullist numlist superscript subscript outdent indent | alignleft aligncenter alignright alignjustify',
                  ],
                }}
              />
            )}
            <SaveButton
              title="Preview"
              saved={saved}
              setSaved={setSaved}
              onClickEvent={() => {
                if (editorRef.current) {
                  const newContent = {
                    ...content,
                    content: editorRef.current.getContent(),
                  };

                  saveEvent(newContent);
                } else {
                  saveEvent(content);
                }
              }}
            />
          </div>
        ) : (
          <>
            {content.media && (
              <>
                <TextArea
                  content={content.media}
                  onChangeEvent={(e) =>
                    setContent({ ...content, media: e.target.value })
                  }
                  placeholder="media"
                />
              </>
            )}
            <TextArea
              content={content.content}
              onChangeEvent={(e) =>
                setContent({ ...content, content: e.target.value })
              }
              placeholder="content"
            />
            <SaveButton
              title="Save"
              saved={saved}
              setSaved={setSaved}
              onClickEvent={() => {
                if (editorRef.current) {
                  const newContent = {
                    ...content,
                    content: editorRef.current.getContent(),
                  };
                  saveEvent(newContent);
                } else {
                  saveEvent(content);
                }
              }}
            />
          </>
        )}
      </div>
    </div>
  );
}
