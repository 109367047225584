import styled from 'styled-components';
import { themeColors } from '../../styles';

export const ContentPanel = styled.div`
  font-size: '1rem';
  margin: 0;
  padding: 10px;
  color: ${themeColors.white.primary};
  sup {
    font-size: 0.6em;
  }
`;
