export function moveToTarget(
  rotation: number,
  rotationTarget: number,
  setRotation: any
) {
  return setInterval(() => {
    if (rotation < rotationTarget) {
      setRotation(rotation + 1);
    } else if (rotation > rotationTarget) {
      setRotation(rotation - 1);
    }
  }, 16);
}

export function mouseDownEv(e: any, mouseDown: any, mouseStart: any): void {
  mouseDown.current = true;
  mouseStart.current = {
    x: e.clientX - e.target.offsetLeft,
    y: e.clientY - e.target.offsetTop,
  };
}

export function touchStartEv(e: any, mouseDown: any, mouseStart: any): void {
  const moveX = e.changedTouches[0].clientX;
  const moveY = e.changedTouches[0].clientY;
  mouseDown.current = true;
  mouseStart.current = {
    x: moveX,
    y: moveY,
  };
}

export function mouseUpEv(mouseDown: any): void {
  mouseDown.current = false;
}

export function touchEndEv(mouseDown: any): void {
  mouseDown.current = false;
}

export function wheelEv(
  e: any,
  setRotation: React.Dispatch<React.SetStateAction<any>>,
  isMoving: any,
  touchSense: number,
  rotation: number,
  maxRotation: number,
  isBeingAnimated?: any
): void {
  isMoving.current = false;
  isBeingAnimated.current = false;

  if (e.deltaY > 0) {
    if (isMoving.current === false) {
      isMoving.current = true;

      if (rotation === maxRotation) {
        setRotation(1);
      }
    }
  } else if (e.deltaY < 0) {
    if (isMoving.current === false) {
      isMoving.current = true;

      if (rotation === 0) {
        setRotation(maxRotation);
      }
    }
    //  setRotation(rotation - 1 < 1 ? 0 : rotation - 1);
  }
}
export function mouseOutEv(mouseDown: any, mouseStart: any): void {
  mouseDown.current = false;
  mouseStart.current = {
    x: 0,
    y: 0,
  };
}

export function mouseMoveEv(
  e: any,
  setRotationY: React.Dispatch<React.SetStateAction<any>>,
  setRotation: React.Dispatch<React.SetStateAction<any>>,
  mouseDown: any,
  mouseStart: any,
  maxRotation: number
): void {
  if (mouseDown.current) {
    if (
      e.clientY - e.target.offsetTop < mouseStart.current.y ||
      e.clientY - e.target.offsetTop > mouseStart.current.y
    ) {
      if (e.clientY - e.target.offsetTop > mouseStart.current.y) {
        setRotationY((rotationY: number) =>
          rotationY >= 2 ? 2 : rotationY + 0.025
        );
        mouseStart.current = {
          x: e.clientX - e.target.offsetLeft,
          y: e.clientY - e.target.offsetTop,
        };
      } else if (e.clientY - e.target.offsetTop < mouseStart.current.y) {
        setRotationY((rotationY: number) =>
          rotationY <= 0 ? 0 : rotationY - 0.025
        );
        mouseStart.current = {
          x: e.clientX - e.target.offsetLeft,
          y: e.clientY - e.target.offsetTop,
        };
      }
    }
    if (e.clientX - e.target.offsetLeft > mouseStart.current.x) {
      setRotation((rotation: number) =>
        rotation >= maxRotation ? 0 : rotation + 1
      );
      mouseStart.current = {
        x: e.clientX - e.target.offsetLeft,
        y: e.clientY - e.target.offsetTop,
      };
    } else if (e.clientX - e.target.offsetLeft < mouseStart.current.x) {
      setRotation((rotation: number) =>
        rotation <= 1 ? maxRotation : rotation - 1
      );
      mouseStart.current = {
        x: e.clientX - e.target.offsetLeft,
        y: e.clientY - e.target.offsetTop,
      };
    }
  }
}

export function touchMoveEv(
  e: any,
  mouseStart: any,
  maxRotation: number,
  setRotation: React.Dispatch<React.SetStateAction<any>>,
  setLevel: React.Dispatch<React.SetStateAction<any>>
): void {
  const moveX = e.changedTouches[0].clientX;
  const moveY = e.changedTouches[0].clientY;
  const isForward = moveX > mouseStart.current.x;
  const isBack = moveX < mouseStart.current.x;
  const isUp = moveY > mouseStart.current.y;
  const isDown = moveY < mouseStart.current.y;

  if (isUp || isDown) {
    if (isUp) {
      setLevel((rotationY: number) => (rotationY >= 2 ? 2 : rotationY + 0.025));
    } else {
      setLevel((rotationY: number) => (rotationY <= 0 ? 0 : rotationY - 0.025));
    }
  } else if (isForward) {
    setRotation((rotation: number) =>
      rotation >= maxRotation ? 0 : rotation + 1
    );
  } else if (isBack) {
    setRotation((rotation: number) =>
      rotation <= 1 ? maxRotation : rotation - 1
    );
  }
  mouseStart.current = {
    x: e.changedTouches[0].clientX,
    y: e.changedTouches[0].clientY,
  };
}
