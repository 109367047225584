import { StoryboardType } from '../../../types';

export const synovitisAnim = (maxRotation: number, cmsData: any) => {
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;
  const storyboardArr: StoryboardType[] = [];
  for (let i = 0; i < maxRotation; i++) {
    const hours = i < 11;
    const weeks = i < 25;
    storyboardArr.push({
      _id: i,
      img: `${appAssets}/synovitis/${i}.jpg`,
      subtitle: 'SynovitisSubtitles',
      timePeriod: {
        title: hours
          ? cmsData.otherItems.timescale[0]
          : weeks
          ? cmsData.otherItems.timescale[2]
          : cmsData.otherItems.timescale[3],
        step: hours ? 1 : weeks ? 2 : 4,
        divisible: hours ? 1 : weeks ? 2 : 4,
      },
      allowControls: true,
      video: `${appAssets}/synovitis.mp4`,
    });
  }
  return storyboardArr;
};
