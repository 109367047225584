import { sourceContentJS } from '../cmsConfig';

const combineData = (dataIn: any) => {
  const newJSON: any = {};
  const dataKeys = Object.keys(dataIn);
  for (let j = 0; j < dataKeys.length; j++) {
    const dataStub = dataIn[dataKeys[j]];

    const dataStubKeys = Object.keys(dataStub);

    for (let i = 0; i < dataStubKeys.length; i++) {
      newJSON[dataStubKeys[i]] = dataStub[dataStubKeys[i]];
    }
  }

  return newJSON;
};

export const getDataNoServer = async (
  hotspotFile: any,
  page: any,
  setContent: any,
  type: any,
  sourceData: any
) => {
  const data = sourceContentJS(combineData(sourceData));
  const originalContent = data[hotspotFile];
  const contentPage = originalContent.content ? originalContent.content[0] : '';
  const nbrPages = !contentPage.content ? 1 : contentPage.content.length;
  const contentType = contentPage.type || 'dynamic';

  const newJSON = {
    content: '',
    textContent: '',
    pages: nbrPages,
    type: contentType,
    label: originalContent.label,
    media: '',
    hoverLabel: '',
  };

  switch (contentType) {
    case 'html-text':
      newJSON.content = contentPage.html_content;
      break;

    case 'mix-content':
    case 'dynamic':
      const contentTab = contentPage ? contentPage.content[page] : '';
      const subType = contentTab ? contentTab.type : '';

      switch (subType) {
        case 'img':
          newJSON.media = contentTab.source;
          newJSON.content = contentTab.textContent.html_content;
          break;

        case 'html-text':
          newJSON.content = contentTab.html_content;
          break;
      }

      break;
  }
  setContent(newJSON);

  return;
};
