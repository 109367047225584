import React from 'react';
import { RightMenu, RightMenuButton } from '../components';
import { Page } from '../types';
import { ContentContext } from '../context';
import { getHotspotPages } from '../utils/getHotspotPages';

export function RightMenuOne(props: {
  setPage: React.Dispatch<React.SetStateAction<any>>;
  page: Page;
}) {
  const { page, setPage } = props;

  const [openItem, setOpenItem] = React.useState(-1);
  let isOrange = 0;

  return (
    <ContentContext.Consumer>
      {({ cmsData, editButton }) => {
        return (
          <RightMenu>
            {getHotspotPages(cmsData.hotspots, page).map((item, index) => {
              isOrange =
                item.config && !item.config.white ? isOrange + 1 : isOrange;

              return (
                <RightMenuButton
                  isOpen={openItem === index}
                  setOpenItem={setOpenItem}
                  index={index}
                  item={item}
                  editButton={editButton(item.file, 'hotspots')}
                  setPage={setPage}
                  key={`index-menu-${index}`}
                  firstOrange={isOrange === 1 && index > 0}
                />
              );
            })}
          </RightMenu>
        );
      }}
    </ContentContext.Consumer>
  );
}
