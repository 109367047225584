import React from 'react';
import AppLayout from './AppLayout';
import { FullCentralWrapper } from './styles';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <FullCentralWrapper>
        <Routes>
          <Route
            path="/:param?"
            element={
              <>
                <AppLayout />
              </>
            }
          />
        </Routes>
      </FullCentralWrapper>
    </BrowserRouter>
  );
}

export default App;
