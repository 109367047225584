import React from 'react';

export function CMSWrapper(props: { children: any }) {
  const { children } = props;
  return (
    <div
      style={{
        display: 'flex',
        zIndex: 100,
        position: 'fixed',
        left: 0,
        top: 0,
        fontFamily: 'Roboto,sans-serif',
        width: '100vw',
        maxHeight: '100vh',
        maxWidth: 600,
      }}
    >
      {children}
    </div>
  );
}
