import { StoryboardType } from '../../../types';

export const storyboardSubclinicalBleedPostAnimation = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: 1,
    img: `${appAssets}/ankle/healthyExternal/zoom0Level1/Ankle_HealthyExternal_Zoom1_0000.jpg`,
    subtitle: '',
  });
  return storyboardArr;
};
