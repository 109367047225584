import { StoryboardType } from '../../../types';

export const arthropathy = () => {
  const storyboardArr: StoryboardType[] = [];
  const appAssets: string | undefined = process.env.REACT_APP_DIRECTORY;

  storyboardArr.push({
    _id: 0,
    img: `${appAssets}/ankle/synovium/zoom0Level1/Orbit_Ankle_Synovitus_Zoom1_0000.jpg`,
    allowControls: false,
  });
  return storyboardArr;
};
