export const setDataNoServer = async (
  hotspotFile: any,
  page: any,
  content: any,
  type: any,
  exportJSON: any,
  setExportJSON: any
) => {
  const newJson = structuredClone(exportJSON);
  const contentParent = newJson[type][hotspotFile];
  const contentStub = contentParent.content
    ? contentParent.content[0]
    : contentParent;
  const contentType = contentStub.type || 'dynamic';

  switch (contentType) {
    case 'mix-content':
    case 'dynamic':
      const pageContent = contentStub.content
        ? contentStub.content[page]
        : null;
      if (pageContent) {
        const subType = pageContent.type;
        switch (subType) {
          case 'img':
            pageContent.textContent.html_content = content.content;
            pageContent.textContent.media = content.media;
            break;
          case 'html-text':
            pageContent.html_content = content.content;
            break;
        }
      }

      contentParent.label = content.label;

      break;

    case 'html-text':
      contentStub.html_content = content.content;
      contentParent.label = content.label;
      break;
  }

  setExportJSON(newJson);
};
