import styled from 'styled-components';

export const FooterWrapper = styled.div<{
  isOpen?: boolean;
  theme: any;
}>`
  transition: max-height 0.5s;
  width: 100%;
  position: fixed;
  bottom: 0;
  max-height: ${(props) => (props.isOpen ? '60vh' : '30px')};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
`;
export const FooterContent = styled.div<{
  isOpen?: boolean;
  theme: any;
}>`
  max-height: ${(props) => (props.isOpen ? '60vh' : '0px')};
  background: ${(props) => props.theme.brand.primary};
  position: fixed;
  bottom: 30px;
  width: 100%;
  overflow: hidden;
  transition: all 0.5s;
  box-sizing: border-box;
  padding: ${(props) => (props.isOpen ? '0 30px 130px 0' : '0px')};
  color: ${(props) => props.theme.brand.menuNoHoverText};
  overflow: auto;
`;

export const FooterWrapperInner = styled.div<{
  isOpen?: boolean;
  theme: any;
}>`
  width: calc(100% - 62px);
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 2;
  margin-top: -32px;
  margin-left: 62px;
`;

export const FooterToggle = styled.button<{
  isOpen?: boolean;
}>`
  transition: color 0.2s;
  color: #fff;
  background: none;
  border: none;
  font-size: 18px;
  margin-right: 20px;
  position: absolute;
  top: -2px;
  right: 0px;
  transition: transform 2s;
  cursor: pointer;
  transform: ${(props) => (props.isOpen ? 'rotate(45deg)' : 'rotate(0deg)')};
  &:hover {
    color: #000;
  }
`;
