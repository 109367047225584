import React, { useState } from 'react';
import {
  RectangleButtonStyle,
  RectangleButtonSquareStyle,
  RectangleButtonWrapperStyle,
  RectangleButtonWrapperInnerStyle,
  ContentPanelStyle,
  RectangleButtonSquareInnerStyle,
  ContentPanelInnerStyle,
} from './styles';
import { Column } from '../../../../components';
import parse from 'html-react-parser';
import AddIcon from '@mui/icons-material/Add';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { ThemeContext, HotspotContext } from '../../../../context';
import { themeInjection } from '../../../../utils/themeInjection';
export const RightMenuButton = (props: {
  item: any;
  content?: string;
  references?: string[];
  isOpen?: boolean;
  index: number;
  setOpenItem?: React.Dispatch<React.SetStateAction<number>>;
  editButton?: any;
  setPage: any;
}) => {
  const { item, isOpen, setOpenItem, index, editButton, setPage } = props;
  const { label, config, subtitle } = item;
  const contentAssets: string | undefined =
    process.env.REACT_APP_CONTENT_IMAGE_DIRECTORY;

  let isWhite = true;
  let onClickEvent: null | (() => any) = null;
  let clickable = false;
  let icon = '';
  if (config) {
    const newPageConfig = {
      page: config.setPage,
      transitionFrame: config.transitionFrame,
      animateOut: config.animateOut,
      backEvent: config.backEvent,
      visible: config.visible,
    };
    isWhite = config.white;
    onClickEvent = config.setPage ? () => setPage(newPageConfig) : null;
    clickable = config.setPage > 0;
    icon = config.icon;
  }
  const content = item.content ? item.content[0].content[0].html_content : '';
  const [fullscreen, setFullscreen] = useState(false);

  function setOpen(setIsOpen: any) {
    if (setOpenItem) {
      setOpenItem(isOpen ? -1 : index);
      setIsOpen(!isOpen ? true : false);
    }
  }

  return (
    <>
      <HotspotContext.Consumer>
        {({ isOpenOne, setIsOpen }) => (
          <ThemeContext.Consumer>
            {({ theme }) => (
              <Column
                style={{
                  width: '100%',
                  marginRight: 42,
                }}
                a="flex-start"
              >
                <RectangleButtonWrapperStyle
                  clickable={clickable}
                  white={false}
                  isOpen={isOpen || false}
                  theme={theme}
                >
                  {editButton && editButton}
                  <RectangleButtonWrapperInnerStyle
                    onClick={() =>
                      onClickEvent
                        ? onClickEvent()
                        : setOpenItem
                        ? setOpen(setIsOpen)
                        : null
                    }
                  >
                    <RectangleButtonSquareStyle
                      white={isWhite}
                      theme={theme}
                      isOpen={isOpen || false}
                    >
                      <RectangleButtonSquareInnerStyle isOn={isOpen}>
                        {icon === 'go' ? <PlayArrowIcon /> : <AddIcon />}
                      </RectangleButtonSquareInnerStyle>
                    </RectangleButtonSquareStyle>
                    <RectangleButtonStyle
                      clickable={clickable}
                      white={isWhite}
                      isOpen={isOpen || false}
                      theme={theme}
                    >
                      <Column a="flex-start">
                        <span style={{ fontWeight: 'bold' }}>{label} </span>{' '}
                        {parse(subtitle ? subtitle : '')}
                      </Column>
                    </RectangleButtonStyle>
                  </RectangleButtonWrapperInnerStyle>
                </RectangleButtonWrapperStyle>

                {content && (
                  <ContentPanelStyle
                    isOpen={isOpen || false}
                    fullscreen={fullscreen}
                    onClick={() => setFullscreen(!fullscreen)}
                  >
                    <ContentPanelInnerStyle
                      isOpen={isOpen || false}
                      theme={theme}
                    >
                      {parse(
                        themeInjection(
                          content.replaceAll('::imageurl::', contentAssets),
                          theme
                        ) || ''
                      )}
                    </ContentPanelInnerStyle>
                  </ContentPanelStyle>
                )}
              </Column>
            )}
          </ThemeContext.Consumer>
        )}
      </HotspotContext.Consumer>
    </>
  );
};
