import React from 'react';
import { EloctaBG } from './styles';
import { Fade } from '../../components';
import { PageType, Page } from '../../types';
import { ThemeContext, ContentContext, HotspotContext } from '../../context';
import { RightMenuElocta } from './RightMenuElocta';
import { Subtitle } from './components/Subtitle';
import { shouldRender, shouldFadeOut } from '../../utils/fadeAndVisible';

export function Elocta(props: {
  thisPage: Page;
  pageArray: PageType[];
  changePage: (newPage: PageType) => void;
}) {
  const { changePage, pageArray, thisPage } = props;
  const [hotspotIsOpen, setHotspotIsOpen] = React.useState(false);

  return (
    <Fade
      visible={!shouldFadeOut(pageArray, thisPage)}
      shouldRender={shouldRender(pageArray, thisPage)}
    >
      <HotspotContext.Provider
        value={{ isOpen: hotspotIsOpen, setIsOpen: setHotspotIsOpen }}
      >
        <ThemeContext.Consumer>
          {({ theme }) => (
            <ContentContext.Consumer>
              {({ cmsData, editButton }) => {
                return (
                  <EloctaBG>
                    <Subtitle
                      fileName={cmsData.subtitles.EloctaSubtitles.file}
                      fadeOut={hotspotIsOpen}
                    />
                    <RightMenuElocta setPage={changePage} page={Page.Elocta} />
                  </EloctaBG>
                );
              }}
            </ContentContext.Consumer>
          )}
        </ThemeContext.Consumer>
      </HotspotContext.Provider>
    </Fade>
  );
}
