export function themeInjection(strIn: string, theme: any) {
  return strIn
    .replace(/brand\.primary/gi, theme.brand.primary)
    .replace(/brand\.secondary/gi, theme.brand.secondary)
    .replace(/font\.h6/gi, theme.font.h6)
    .replace(/font\.p/gi, theme.font.p)
    .replace(/font\.sub/gi, theme.font.sub)
    .replace(/brand\.link/gi, theme.brand.link)
    .replace(/brand\.menuNoHoverText/gi, theme.brand.menuNoHoverText);
}
