import React from 'react';
import { themeColors } from '../styles';

export const DeviceContext = React.createContext('desktop');

export const ContentContext = React.createContext<{
  cmsData: any;
  editButton: any;
  cmsIsEnabled: boolean;
}>({
  cmsData: {},
  editButton: (file: string) => null,
  cmsIsEnabled: false,
});

export const ThemeContext = React.createContext<any>({ theme: themeColors });

export const HotspotContext = React.createContext<any>({
  isOpen: false,
  setIsOpen: () => null,
});

export const ComponentContext = React.createContext<any>({
  homeButton: false,
  setHomeButton: () => null,
  ankeKnee: 'knee',
  setAnkeKnee: () => null,
});
